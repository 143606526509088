<template>
  <div id="area">
    <router-link to="/" id="logo"><img src="../assets/logo.png"/></router-link>
    <div id="inner-wrapper">
      <div id="drawer" :style="{ left: leftOffset }">
        <div id="s1" class="card">
          <button
            @click="choose('skins')"
            data-price="$9.99"
            :class="{ chosen: paymentMethod === 'skins' }"
          >
            <span>Pay with Skins</span>
          </button>
          <button
            @click="choose('gumroad')"
            data-price="$7.99"
            :class="{ chosen: paymentMethod === 'gumroad' }"
          >
            <span>Pay with Cash</span>
          </button>
        </div>
        <div id="s2" class="card">
          <Gumroad v-show="paymentMethod === 'gumroad'" @nextStep="step = 2" />
          <SkinPayment
            v-show="paymentMethod === 'skins'"
            @nextStep="step = 2"
          />
        </div>
        <div id="last-card" class="card">
          <h1>You're all set!</h1>
          <p>
            Thank you for purchasing TradeUpHero Pro.<br />
            Start finding the most profitable TradeUps around in the
            <a :href="dashboard">Dashboard</a>
          </p>
        </div>
      </div>
      <nav>
        <button id="prev" v-if="step === 1" @click="step--">
          <img src="../assets/darkArrow.svg" />Prev.
        </button>
        <div v-else></div>
        <button
          id="next"
          @click="step++"
          v-if="step === 0 && paymentMethod !== ''"
        >
          Next<img src="../assets/darkArrow.svg" />
        </button>
      </nav>
    </div>
  </div>
</template>

<script>
import SkinPayment from "@/components/SkinPayment";
import Gumroad from "@/components/Gumroad";
export default {
  name: "Subscribe",
  components: { SkinPayment, Gumroad },
  data() {
    return {
      dashboard: process.env.VUE_APP_DOMAIN,
      step: 0,
      paymentMethod: "",
    };
  },
  methods: {
    choose(method) {
      this.paymentMethod = method;
      this.step = 1;
      this.$gtag.event("begin_checkout", { value: method });
    },
  },
  computed: {
    leftOffset() {
      const options = ["var(--card1)", "var(--card2)", "var(--card3)"];
      return options[this.step];
    },
  },
  beforeMount() {
    if (!this.$store.state.user.signedIn) {
      window.location = `${process.env.VUE_APP_API_DOMAIN}/auth/authenticate`;
    }
  },
};
</script>

<style lang="scss" scoped>
#area {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding-top: 110px;
  padding-left: 220px;
  padding-right: 30px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  --card1: 25%;
  --card2: -45%;
  --card3: -115%;
  #logo {
    position: absolute;
    top: 80px;
    width: 186px;
    left: calc(50% - 93px);
    z-index: 30;
  }
}
#inner-wrapper {
  width: 90%;
  height: 90%;
  position: absolute;
  top: 5%;
  left: 5%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
  }
  #drawer {
    display: flex;
    flex-wrap: nowrap;
    width: auto;
    height: 60%;
    position: relative;
    transition: left 0.5s ease-in-out;
    #s1 {
      display: flex;
      justify-content: space-around;
      align-items: center;
      button {
        width: 40%;
        height: 70%;
        background: #ffffff;
        border: 1px solid #8748fb;
        box-sizing: border-box;
        border-radius: 4px;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span {
          position: relative;
          z-index: 5;
          font-family: Karla;
          font-style: normal;
          font-weight: bold;
          font-size: 36px;
          line-height: 42px;
          text-align: center;
          color: #012a36;
          max-width: 60%;
          white-space: wrap;
        }
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          background-color: #c1eeff;
          position: absolute;
          top: -100%;
          right: -100%;
          z-index: 1;
          transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
          border-radius: 100%;
        }
        &::after {
          content: attr(data-price);
          font-family: Karla;
          font-style: normal;
          font-weight: bold;
          font-size: 56px;
          line-height: 24px;
          letter-spacing: 1px;
          color: #eb4b4b;
          margin-top: 40px;
          position: relative;
          z-index: 5;
        }
        &:hover,
        &.chosen {
          cursor: pointer;
          &::before {
            width: 300%;
            height: 300%;
          }
        }
      }
    }
    #last-card {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      font-family: Karla;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 20px;
      color: #ffffff;
      p {
        margin-bottom: 10vh;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        a {
          color: #c1eeff;
        }
      }
    }
  }
}
nav {
  position: absolute;
  width: 50%;
  margin-left: 25%;
  top: calc(90% + 26px);
  display: flex;
  justify-content: space-between;
  button {
    background: #d8b2c5;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
      0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    border: none;
    padding: 7px 25px;
    display: flex;
    align-items: center;
    font-family: Karla;
    color: #012a36;
    font-size: 18px;
    line-height: 21px;
    &:hover {
      cursor: pointer;
    }
  }
  #prev {
    img {
      margin-right: 14px;
    }
  }
  #next {
    img {
      transform: rotate(180deg);
      margin-left: 14px;
    }
  }
}
.card {
  width: 50%;
  flex-shrink: 0;
  height: 100%;
  background: rgba(238, 250, 255, 0.2);
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  margin-right: 20%;
  border-radius: 5px;
  position: relative;
}
@media only screen and (max-width: 1365px) {
  #area {
    --card1: 5%;
    --card2: -105%;
    --card3: -215%;
  }
  .card {
    width: 90%;
  }
  #drawer {
    height: 75% !important;
  }
  #inner-wrapper {
    justify-content: center;
    padding-top: 30px;
  }
  #s1 {
    flex-direction: column;
    button {
      width: 80% !important;
      height: 40% !important;
      justify-content: center !important;
      span {
        max-width: 80% !important;
        font-size: 26px !important;
      }
      &::after {
        font-size: 36px !important;
        margin-top: 20px !important;
      }
    }
  }
  nav {
    top: auto;
    bottom: 0px;
    width: 90%;
    margin-left: 5%;
  }
  img {
    display: none;
  }
}
</style>
