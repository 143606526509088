<template>
  <div id="outer-wrapper" v-if="active">
    <div id="wrapper">
      <div id="head">
        <img src="../assets/closeX.svg" @click="close" />
        <h3>What's new?</h3>
        <span @click="nextStep">{{ step === 5 ? "Close" : "Next" }}</span>
      </div>
      <img class="big-img" v-show="step === 0" src="../assets/Confetti.svg" />
      <img class="big-img" v-show="step === 1" src="../assets/Diamond.svg" />
      <img class="big-img" v-show="step === 2" src="../assets/Flash.svg" />
      <img class="big-img" v-show="step === 3" src="../assets/Security.svg" />
      <img class="big-img" v-show="step === 4" src="../assets/ProUser.svg" />
      <img class="big-img" v-show="step === 5" src="../assets/Twitter.svg" />
      <template v-for="(info, n) in infos">
        <template v-if="step === n">
          <h2 :key="info.header">{{ info.header }}</h2>
          <p :key="info.header" v-html="info.text"></p>
        </template>
      </template>

      <div id="points">
        <div
          class="point"
          v-for="(info, n) in infos"
          :key="n"
          :class="{ active: n <= step }"
          @click="step = n"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsPopup",
  data() {
    return {
      step: 0,
      active: false,
      infos: [
        {
          header: "What’s new?",
          text: "Hello! We are happy to announce some changes to the site!",
        },
        {
          header: "New Design",
          text:
            "We changed the look and feel of the site to provide you with a more pleasant experience.",
        },
        {
          header: "Improved performance",
          text:
            "We worked hard to accelerate the calculations for new, profitable tradeups.",
        },
        {
          header: "Updated policies",
          text:
            "We updated our Terms of Service and Privacy Policy in order to protect you as our member.",
        },
        {
          header: "Pro users",
          text:
            "Due to technical restrictions, you are required to re-enter your Gumroad subscription key in your Account settings.",
        },
        {
          header: "Feedback",
          text:
            "We really appreciate you using our service and we’d love to hear what you have to say! So feel free to contact us anytime and consider following us on <a href='https://twitter.com/tradeuphero' target='_blank' style='color: #D8B2C5;'>twitter.</a>",
        },
      ],
    };
  },
  methods: {
    nextStep() {
      this.step++;
      if (this.step >= this.infos.length) this.close();
    },
    close() {
      this.active = false;
      localStorage.setItem("beta-news-seen", true);
    },
  },
  beforeMount() {
    if (!localStorage.getItem("beta-news-seen")) this.active = true;
  },
};
</script>

<style lang="scss" scoped>
#outer-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100000;
}
#wrapper {
  position: fixed;
  top: 20vh;
  left: 35vw;
  width: 30vw;
  background-color: #c1eeff;
  z-index: 10000;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  #head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    padding-bottom: 22px;
    border-bottom: 1px solid rgba(69, 168, 206, 0.2);
    box-sizing: border-box;
    h3 {
      margin: 0;
      font-family: Karla;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #012a36;
    }
    span {
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #eb4b4b;
      user-select: none;
      &:hover {
        cursor: pointer;
      }
    }
    img {
      height: 12px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .big-img {
    width: 100px;
    height: 100px;
    padding: 5px;
    background-color: #d8b2c5;
    border-radius: 50%;
    margin-top: 60px;
  }
  h2 {
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 43px;
    color: #012a36;
    margin: 0;
  }
  p {
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #45575c;
    width: 60%;
    height: 90px;
  }
  #points {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    .point {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #9c9c9c;
      margin-right: 5px;
      margin-left: 5px;
      &.active {
        background-color: #eb4b4b;
      }
    }
  }
}
@media only screen and (max-width: 1365px) {
  #outer-wrapper #wrapper {
    top: auto;
    bottom: 20px;
    left: 5%;
    width: 90%;
    text-align: center;
    p {
      height: auto;
      min-height: 90px;
    }
  }
}
</style>