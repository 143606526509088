<template>
  <div id="area">
    <div id="content" :data-header="header" v-html="text"></div>
  </div>
</template>

<script>
export default {
  name: "Legal",
  props: {
    header: String,
    text: String,
  },
};
</script>

<style lang="scss" scoped>
#area {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  height: auto;
  min-height: 100vh;
}
#content {
  box-sizing: border-box;
  width: 100%;
  background: rgba(238, 250, 255, 0.2);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding: 50px;
  position: relative;
  margin-top: 30px;

  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #ffffff;
  &::before {
    content: attr(data-header);
    position: absolute;
    bottom: calc(100% + 20px);
    left: 0;
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;

    color: #ffffff;
  }
}
@media only screen and (max-width: 1365px) {
  #content {
    margin-top: 0;
    &::before {
      display: none;
      margin-top: 0;
    }
  }
}
</style>

<style>
#content a {
  color: #c1eeff;
}
</style>