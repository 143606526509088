<template>
  <div
    id="slider"
    :style="{
      '--handle-color': locked ? '#9E9E9E' : handleColor,
      '--track-color': locked ? 'rgba(158, 158, 158, 0.45)' : trackColor,
    }"
  >
    <div id="input-wrapper" v-if="valueAbove">
      {{ valueName }}
      <input
        type="text"
        v-model="value"
        :style="{ width: (value + '').length * 12 + 'px' }"
      />
      {{ valuePostFinished }}
    </div>
    <input
      type="range"
      v-bind:min="min"
      v-bind:max="max"
      :value="value"
      @input="input"
      :step="step"
      :disabled="locked"
    />
    <div id="input-wrapper" v-if="!valueAbove && !locked">
      <input
        type="text"
        v-model="value"
        :style="{ width: (value + '').length * 12 + 'px' }"
        :disabled="locked"
      />
      {{ valuePostFinished }}
    </div>
    <div id="pro" v-else-if="locked">PRO</div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Slider",
  props: {
    min: String,
    max: String,
    value: Number,
    valuePost: {
      type: String,
      required: false,
      default: "",
    },
    valuePostFull: {
      type: String,
      required: false,
      default: "",
    },
    step: {
      type: Number,
      required: false,
      default: 1,
    },
    valueAbove: {
      type: Boolean,
      required: false,
      default: false,
    },
    ceil: {
      type: Number,
      required: false,
      default: null,
    },
    floor: {
      type: Number,
      required: false,
      default: null,
    },
    valueName: {
      type: String,
      required: false,
      default: "",
    },
    handleColor: {
      type: String,
      required: false,
      default: "#012a36",
    },
    trackColor: {
      type: String,
      required: false,
      default: "#eb4b4b",
    },
    locked: Boolean,
  },
  computed: {
    valuePostFinished: function () {
      if (this.value == 100) return this.valuePostFull;
      else return this.valuePost;
    },
  },
  methods: {
    input: function ({ target }) {
      if (this.floor && target.value < this.floor) {
        this.$emit("input", Number.parseFloat(this.floor));
        this.$emit("change", Number.parseFloat(this.floor));
      } else if (this.ceil && target.value > this.ceil) {
        this.$emit("input", Number.parseFloat(this.ceil));
        this.$emit("change", Number.parseFloat(this.ceil));
      } else {
        this.$emit("input", Number.parseFloat(target.value));
        this.$emit("change", Number.parseFloat(target.value));
      }
    },
    /*
    change: function () {
      this.$emit("input", this.value);
      this.$emit("change", this.value);
    },*/
  },
};
</script>

<style scoped lang="scss">
#slider {
  width: 100%;
  position: relative;
}
input[type="range"] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type="range"] {
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    background-color: var(--handle-color);
    border: none;
    border-radius: 10px;
    position: relative;
    top: -7px;
  }
  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background-color: var(--handle-color);
    border: none;
    border-radius: 10px;
  }
  &::-ms-thumb {
    width: 20px;
    height: 20px;
    background-color: var(--handle-color);
  }
  &:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: var(--track-color);
    border-radius: 3px;
  }
  &:focus::-webkit-slider-runnable-track {
    background: var(--track-color);
  }
  &::-moz-range-track {
    width: 100%;
    height: 5px;
    background: var(--track-color);
    border-radius: 3px;
  }
  &::-ms-track {
    width: 100%;
    cursor: pointer;
    background-color: var(--track-color);
  }
}

input[type="text"] {
  background-color: transparent;
  border: none;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-family: Karla;
}
input[type="text"]:focus {
  outline: 0;
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

#input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin-top: 5px;
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
}
#pro {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin-top: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #8748fb;
}
</style>