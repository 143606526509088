<template>
  <div id="outer" :style="{ '--color': color }">
    <div id="wrapper" @click.stop="toggle" :style="{ width, height }">
      <svg
        id="check"
        x="0px"
        y="0px"
        viewBox="0 0 511.999 511.999"
        v-if="value"
      >
        <g>
          <g>
            <path
              :fill="color"
              d="M506.231,75.508c-7.689-7.69-20.158-7.69-27.849,0l-319.21,319.211L33.617,269.163c-7.689-7.691-20.158-7.691-27.849,0
			c-7.69,7.69-7.69,20.158,0,27.849l139.481,139.481c7.687,7.687,20.16,7.689,27.849,0l333.133-333.136
			C513.921,95.666,513.921,83.198,506.231,75.508z"
            />
          </g>
        </g>
      </svg>
    </div>
    <div :style="{ marginLeft: '10px' }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    value: Boolean,
    color: {
      type: String,
      required: false,
      default: "#c1eeff",
    },
    width: {
      type: String,
      required: false,
      default: "20px",
    },
    height: {
      type: String,
      required: false,
      default: "20px",
    },
  },
  data() {
    return {
      active: this.value,
    };
  },
  methods: {
    toggle() {
      this.$emit("input", !this.value);
    },
  },
};
</script>

<style scoped lang="scss">
#outer {
  display: flex;
  --color: attr(data-color);
}

#check {
  width: 16px;
}
#wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid var(--color);
  box-sizing: border-box;
  border-radius: 2px;
  &:hover {
    cursor: pointer;
  }
  img {
    width: 100%;
  }
}
</style>
