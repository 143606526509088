<template>
  <div id="wrapper">
    <div id="top-half">
      <div id="links" v-if="!placeholder">
        <img
          v-if="favorite"
          src="../assets/favoriteFull.svg"
          @click="removeFavorite()"
          title="Remove from favorites"
        />
        <img
          v-else
          src="../assets/favorite.svg"
          @click="addFavorite()"
          title="Add to favorites"
        />
        <div id="share">
          <div
            id="share-background"
            v-if="showingShare"
            @click.stop="showingShare = false"
          ></div>
          <img
            src="../assets/share.svg"
            @click.stop="showingShare = !showingShare"
            title="Share"
          />
          <div
            id="share-popup"
            v-if="showingShare"
            v-click-outside="closePopup"
          >
            <div id="link-line">
              <img src="../assets/link.svg" />
              <input readonly :value="shareLink" :id="shareLinkID" />
              <button @click="copy">{{ copied ? "Copied" : "Copy" }}</button>
            </div>
            <div id="or-share">or share on</div>
            <div id="social-links">
              <div @click="shareTwitter">
                <img src="../assets/twitterSmall.svg" />
              </div>
              <div @click="shareFacebook">
                <img src="../assets/facebook.svg" />
              </div>
              <a :href="shareMailLink"><img src="../assets/email.svg"/></a>
            </div>
          </div>
        </div>
      </div>
      <div id="links" v-else>
        <div class="placeholder icon"></div>
        <div class="placeholder icon"></div>
      </div>
      <div id="profit" v-if="!placeholder">
        <span
          :style="{ color: getColor(Number.parseInt(tradeup.profit.percent)) }"
          >{{ tradeup.profit.percent }}</span
        >
        <span
          :style="{ color: getColor(Number.parseInt(tradeup.profit.percent)) }"
          >{{ profitPrefix }}{{ currency(tradeup.profit.total) }}</span
        >
      </div>
      <div id="profit" v-else>
        <div class="placeholder profit"></div>
        <div class="placeholder profit"></div>
      </div>
      <div id="items-wrapper" v-if="!placeholder">
        <div class="item" :data-price="currency(tradeup.items[0].price)">
          <span class="amount">{{ tradeup.items[0].amount }}x</span>
          <a
            class="name"
            target="_blank"
            rel="noopener noreferrer"
            :href="tradeup.items[0].link"
            >{{ tradeup.items[0].name }}</a
          >
          <img :src="getImage(tradeup.items[0].name)" />
        </div>

        <div class="item" :data-price="currency(tradeup.items[1].price)">
          <span class="amount">{{ tradeup.items[1].amount }}x</span>
          <a
            class="name"
            target="_blank"
            rel="noopener noreferrer"
            :href="tradeup.items[1].link"
            >{{ tradeup.items[1].name }}</a
          >
          <img :src="getImage(tradeup.items[1].name)" />
        </div>
      </div>
      <div id="items-wrapper" v-else>
        <div class="item item-placeholder" v-for="n in 2" :key="'B' + n">
          <div class="amount placeholder"></div>
          <div class="name placeholder"></div>

          <div class="img placeholder" />
        </div>
      </div>

      <div id="add-info" v-if="!placeholder">
        <span class="info-line"
          ><span>Cost:</span><span>{{ currency(tradeup.cost) }}</span>
        </span>
        <span class="info-line"
          ><span>Ø Value:</span>
          <span>{{ currency(tradeup.averageValue) }}</span></span
        >
        <div id="float">
          <FloatMeter
            :max-float="Number.parseFloat(tradeup.maxFloat)"
          ></FloatMeter>
          <span class="info-line"
            ><span>MAX FLOAT:</span> <span>{{ tradeup.maxFloat }}</span></span
          >
        </div>
      </div>
      <div id="add-info" v-else>
        <div class="info-line placeholder"></div>
        <div class="info-line placeholder"></div>
        <div id="float">
          <FloatMeter :max-float="null"></FloatMeter>
          <div class="info-line placeholder"></div>
        </div>
      </div>
      <div id="icon" @click="showingBottom = !showingBottom">
        <img
          src="../assets/chevron.svg"
          :style="{
            transform: showingBottom ? 'rotate(0)' : 'rotate(180deg)',
            opacity: placeholder ? '0' : '1',
          }"
        />
      </div>
    </div>
    <div
      id="toggle-bottom"
      v-show="showingBottom"
      v-if="!placeholder"
      @click="showingAlternatives = !showingAlternatives"
    >
      {{ toggletext }}
    </div>
    <div
      id="bottom-half"
      v-show="showingBottom && !showingAlternatives"
      v-if="!placeholder"
    >
      <div class="item-line">
        <div class="outcomes header">Outcomes</div>
        <div class="odds header">Odds</div>
        <div class="profit header"></div>
        <div class="image header"></div>
        <div class="Float header">Float</div>
        <div class="volume header">Volume</div>
      </div>
      <div id="separator"></div>
      <div
        class="item-line"
        v-for="(item, index) in tradeup.results"
        :key="'C' + index"
      >
        <div class="outcomes">
          <a
            target="_blank"
            rel="noopener noreferrer"
            :href="item.link"
            :data-price="currency(item.price)"
            >{{ item.name }}</a
          >
        </div>
        <div class="odds" :style="{ color: getColor(item.percentageProfit) }">
          {{ item.chance }}%
        </div>
        <div class="profit" :style="{ color: getColor(item.percentageProfit) }">
          {{ currency(item.profit) }}
        </div>
        <div class="image"><img :src="getImage(item.name)" /></div>
        <div class="Float">{{ item.float.toFixed(5) }}</div>
        <div class="volume">{{ item.volume }}</div>
      </div>
    </div>

    <div
      id="bottom-half"
      v-show="showingBottom && showingAlternatives"
      v-if="!placeholder"
    >
      <div class="alternatives-header">
        <div>{{ tradeup.items[0].name }}</div>
        <div>{{ tradeup.items[1].name }}</div>
      </div>
      <div id="separator"></div>
      <div id="alternatives-body">
        <div class="alternative-column">
          <a
            v-for="(item, index) in tradeup.items[0].alternatives"
            :key="'D' + index"
            target="_blank"
            rel="noopener noreferrer"
            :href="item.link"
            :data-price="currency(item.price)"
            >{{ item.name }}</a
          >
        </div>
        <div class="alternative-column">
          <a
            v-for="(item, index) in tradeup.items[1].alternatives"
            :key="'E' + index"
            target="_blank"
            rel="noopener noreferrer"
            :href="item.link"
            :data-price="currency(item.price)"
            >{{ item.name }}</a
          >
        </div>
      </div>
      <div id="back-to-outcomes" @click="showingAlternatives = false">
        <img src="../assets/ArrowLeft.svg" />Back to outcomes
      </div>
    </div>
  </div>
</template>

<script>
import FloatMeter from "@/components/FloatMeter.vue";
import ClickOutside from "vue-click-outside";
import { applyCurrency } from "../util";

export default {
  name: "TradeUp",
  components: {
    FloatMeter,
  },
  props: {
    placeholder: {
      type: Boolean,
      required: false,
      default: false,
    },
    tradeup: {
      type: Object,
      required: false,
    },
    favorite: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showingBottom: false,
      showingAlternatives: false,
      copied: false,
      showingShare: false,
      shareLinkID: `${Math.random()}`,
    };
  },
  computed: {
    toggletext() {
      if (this.showingAlternatives) return "Show outcomes";
      return "Show alternatives";
    },
    shareLink() {
      return `${process.env.VUE_APP_DOMAIN}/calculator?utid=${this.tradeup.uti}`;
    },
    shareMailLink() {
      return `mailto:?subject=Look at this awesome TradeUp I found!&amp;body="Check out this profitable TradeUp I found on TradeUpHero: ${this.shareLink}"`;
    },
    profitPrefix() {
      if (this.tradeup.profit.total > 0) return "+";
      return "";
    },
  },
  methods: {
    currency(value) {
      return applyCurrency(
        value,
        this.$store.state.currency,
        this.$store.state.currencyExchangeRate
      );
    },
    getImage(name) {
      return `${process.env.VUE_APP_API_DOMAIN}/image/${name}/128fx128f`;
    },
    closePopup() {
      this.showingShare = false;
    },
    shareFacebook() {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${this.shareLink}`,
        "winname",
        "directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=no,resizable=no,width=500,height=400"
      );
    },
    shareTwitter() {
      window.open(
        `https://twitter.com/intent/tweet?text=Look at this awesome TradeUp I've found on @TradeUpHero! ${this.shareLink}`,
        "winname",
        "directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=no,resizable=no,width=500,height=400"
      );
    },
    copy() {
      const copyText = document.getElementById(this.shareLinkID);
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.$toasted.show("Copied link to clipboard!");
    },
    getColor(profit) {
      if (profit < 0) return "#DF5151";
      if (profit === 0) return "#F08354";
      if (profit < 0.3) return "#FBDB5D";
      return "#4BEB5B";
    },
    removeFavorite() {
      if (!this.$store.state.user.subscribed) {
        this.$toasted.show(
          "Sorry, saving TradeUps as favorites is a pro feature!"
        );
        return;
      }

      this.$store.commit("removeFavorite", this.tradeup.uti);
      fetch(`${process.env.VUE_APP_API_DOMAIN}/account/favorites?uti=${ this.tradeup.uti}`, {
        method: "DELETE",
        credentials: "include",
      })
        .then((r) => r.json())
        .then((result) => {
          if (result.success) {
            this.$toasted.show("Removed TradeUp from favorites!", {
              action: {
                text: "Undo",
                onClick: () => {
                  this.addFavorite("Restored TradeUp");
                },
              },
              onComplete: () => this.$emit("toastComplete"),
            });
          } else {
            this.$store.commit("addFavorite", this.tradeup.uti);
            this.$toasted.show("Failed to remove TradeUp from favorites!");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("addFavorite", this.tradeup.uti);
          this.$toasted.show("Failed to remove TradeUp from favorites!");
        });
    },

    addFavorite(msg = "Added TradeUp to favorites!") {
      if (!this.$store.state.user.subscribed) {
        this.$toasted.show(
          "Sorry, saving TradeUps as favorites is a pro feature!"
        );
        return;
      }

      this.$store.commit("addFavorite", this.tradeup.uti);
      fetch(`${process.env.VUE_APP_API_DOMAIN}/account/favorites`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uti: this.tradeup.uti,
        }),
      })
        .then((r) => r.json())
        .then((result) => {
          if (result.success) {
            this.$toasted.show(msg, {
              onComplete: () => this.$emit("toastComplete"),
            });
          } else {
            this.$store.commit("removeFavorite", this.tradeup.uti);
            this.$toasted.show("Failed to add TradeUp to favorites!");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("removeFavorite", this.tradeup.uti);
          this.$toasted.show("Failed to add TradeUp to favorites!");
        });
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style scoped lang="scss">
#wrapper {
  color: whitesmoke;
  background-color: rgba(238, 250, 255, 0.2);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  margin-bottom: 25px;
  font-family: Karla;
  font-style: normal;
  padding: 20px;
  min-height: 150px;
}

#top-half {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;

  height: 150px;
  #links {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 40%;
    align-items: flex-start;
    width: 5%;
    img:hover {
      cursor: pointer;
    }
    #share {
      position: relative;
      #share-background {
        display: none;
      }
      #share-popup {
        z-index: 30;
        position: absolute;
        top: calc(100% + 18px);
        left: -12px;
        width: 500px;
        height: 230px;
        background-color: #d8b2c5;
        border-radius: 4px;
        #link-line {
          display: flex;
          justify-content: center;
          margin-top: 33px;
          input {
            width: 280px;
            height: 25px;
            font-family: Karla;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #012a36;
            background: #f8ddea;
            border-radius: 2px;
            border: none;
            margin: 0 25px 0 15px;
          }
          button {
            font-family: Karla;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #012a36;
            width: 65px;
            padding: 5px 0 5px 0;
            border: none;
            background: rgba(135, 72, 251, 0.5);
            border-radius: 2px;
            &:hover {
              cursor: pointer;
            }
          }
        }
        #or-share {
          font-family: Karla;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          color: #44585e;
          margin-top: 25px;
          padding-bottom: 20px;
          border-bottom: 1px solid #ce9ab4;
          width: 350px;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        }
        #social-links {
          display: flex;
          justify-content: center;
          margin-top: 40px;
          img {
            margin-left: 42px;
            margin-right: 42px;
          }
        }
        &::before {
          content: "";
          position: absolute;
          bottom: 100%;
          left: 10px;
          width: 0;
          height: 0;
          border: solid 10px transparent;
          border-bottom: solid 10px #d8b2c5;
        }
      }
    }
  }
  #profit {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 40%;
    font-weight: bold;
    font-size: 24px;
    width: 15%;
    box-sizing: border-box;
    padding-right: 10%;
  }
  #items-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 50%;
    .item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      position: relative;
      align-items: center;
      .amount {
        font-weight: bold;
        font-size: 17px;
        line-height: 20px;
        width: 10%;
      }
      .name {
        width: 60%;
        font-family: Karla;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        text-decoration: none;
        color: #ffffff;
      }
      img {
        margin: auto;
        height: 54px;
      }
      &::after {
        content: attr(data-price);
        position: absolute;
        top: 100%;
        left: 10%;
        font-size: 14px;
        line-height: 16px;
        color: #d4b2d8;
      }
    }
  }
  #add-info {
    height: 100%;
    width: 15%;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .info-line {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
  #icon:hover {
    cursor: pointer;
    user-select: none;
    img {
      user-select: none;
    }
  }
}

#toggle-bottom {
  margin-left: 21%;
  font-family: Karla;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  color: #eb4b4b;
  &:hover {
    cursor: pointer;
  }
}

#bottom-half {
  .item-line {
    display: flex;
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    .outcomes {
      width: 35%;
      color: #ffffff;
      font-size: 17px;
      line-height: 20px;

      a {
        color: #ffffff;
        text-decoration: none;
        position: relative;
        &::after {
          content: attr(data-price);
          position: absolute;
          top: 100%;
          left: 0;
          font-family: Karla;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: #d4b2d8;
        }
      }
    }
    .odds {
      width: 10%;
    }
    .profit {
      width: 10%;
    }
    .image {
      width: 20%;
      display: flex;
      justify-content: center;
      img {
        height: 54px;
      }
    }
    .Float {
      width: 15%;
    }
    .volume {
      width: 10%;
    }

    .header {
      color: #c1eeff !important;
      font-weight: bold !important;
      font-size: 17px !important;
    }
  }
  #separator {
    width: 100%;
    height: 1px;
    background-color: #c1eeff;
    margin: 10px 0;
  }
  .alternatives-header {
    display: flex;
    justify-content: flex-start;
    div {
      width: 50%;
      font-family: Karla;
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      line-height: 20px;
      color: #c1eeff;
    }
  }
  #alternatives-body {
    display: flex;
    .alternative-column {
      width: 50%;
      display: flex;
      flex-direction: column;
      a {
        font-family: Karla;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        text-decoration: none;
        color: #ffffff;
        margin-bottom: 30px;
        position: relative;
        &::after {
          content: attr(data-price);
          position: absolute;
          left: 0;
          top: 100%;
          font-family: Karla;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: #d4b2d8;
        }
      }
    }
  }
  #back-to-outcomes {
    display: flex;
    justify-content: center;
    font-family: Karla;
    font-weight: bold;
    font-size: 17px;
    color: #c1eeff;
    text-decoration: underline;
    margin-top: 40px;
    margin-bottom: 20px;
    img {
      margin-right: 10px;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.loading-dot {
  animation: loading-dots 1.4s infinite;
  animation-fill-mode: both;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loading-dots {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}
.placeholder {
  background-color: #b9cfd8;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(
      to right,
      transparent 0%,
      #7896a2 50%,
      transparent 100%
    );
    animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
  &.profit {
    width: 50px;
    height: 26px;
  }
  &.amount {
    margin-right: 4%;
    width: 6% !important;
    height: 20px;
  }
  &.img {
    width: 54px;
    height: 54px;
    margin: auto;
  }
  &.info-line {
    height: 19px;
  }
  &.icon {
    width: 18px;
    height: 18px;
  }
}
.item-placeholder::after {
  content: "";
  width: 30px;
  height: 15px;
  background-color: #b9cfd8;
  border-radius: 2px;
}

@media only screen and (max-width: 1365px) {
  #top-half {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-rows: 100px auto auto;
    grid-template-columns: 4fr 6fr;
    grid-template-areas:
      "profit addinfo"
      "items items"
      "chevron chevron";
    position: relative;
    #profit {
      grid-area: profit;
      width: 100%;
      height: 100%;
      justify-content: space-evenly;
      padding: 0;
    }
    #add-info {
      grid-area: addinfo;
      width: 100%;
      box-sizing: border-box;
      max-width: 200px;
      margin: auto;
    }
    #items-wrapper {
      grid-area: items;
      width: 100%;
      margin-top: 30px;
      .item {
        padding-bottom: 15px;
        margin-bottom: 10px;
        img {
          display: none;
        }
        .name {
          width: 90%;
        }
        &::after {
          top: calc(100% - 15px);
        }
      }
    }
    #icon {
      grid-area: chevron;
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 10px 0;
    }
    #links {
      position: absolute;
      bottom: 5px;
      right: 35px;
      flex-direction: row-reverse;
      height: auto;
      width: 70px;
      z-index: 20;
      #share {
        position: static;
        #share-background {
          position: fixed;
          top: 0;
          left: 0;
          z-index: 29;
          width: 100%;
          height: 100vh;
          background: rgba(0, 0, 0, 0.3);
          display: block;
        }
        #share-popup {
          z-index: 30;
          position: fixed;
          top: auto;
          bottom: 10vh;
          left: 20px;
          width: calc(100% - 40px);
          height: auto;
          background-color: #d8b2c5;
          border-radius: 4px;
          padding-bottom: 20px;
          #link-line {
            display: flex;
            justify-content: center;
            margin-top: 33px;
            input {
              width: auto;
              height: 25px;
              font-family: Karla;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 16px;
              color: #012a36;
              background: #f8ddea;
              border-radius: 2px;
              border: none;
              margin: 0 25px 0 15px;
            }
            button {
              font-family: Karla;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 16px;
              color: #012a36;
              width: 65px;
              padding: 5px 0 5px 0;
              border: none;
              background: rgba(135, 72, 251, 0.5);
              border-radius: 2px;
              &:hover {
                cursor: pointer;
              }
            }
          }
          #or-share {
            font-family: Karla;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            color: #44585e;
            margin-top: 25px;
            padding-bottom: 20px;
            border-bottom: 1px solid #ce9ab4;
            width: 350px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
          }
          #social-links {
            display: flex;
            justify-content: center;
            margin-top: 40px;
            img {
              margin-left: 42px;
              margin-right: 42px;
            }
          }
          &::before {
            display: none;
          }
        }
      }
    }
  }
  #bottom-half {
    .item-line {
      display: grid;
      grid-template-rows: auto auto;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas:
        "name name name float"
        "price odds total .";
      margin-bottom: 20px;

      .image {
        display: none;
      }
      .outcomes {
        grid-area: name;
        width: 100%;
      }
      .odds {
        grid-area: odds;
        width: 100%;
        &.header {
          display: none;
        }
      }
      .profit {
        grid-area: total;
        width: 100%;
      }
      .Float {
        grid-area: float;
        width: 100%;
        text-align: end;
      }
      .volume {
        display: none;
        grid-area: volume;
        width: 100%;
      }
    }
  }
}
</style>
