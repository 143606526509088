<template>
  <div
    id="item-card"
    @click="$emit('remove')"
    :class="{
      selected: selected,
      removable: removable,
      'in-payment': inPayment,
    }"
  >
    <div id="overlay"><img src="../assets/X.svg" /></div>
    <div id="name">{{ getFullName(name) }}</div>
    <img
      id="image"
      :src="image"
      onerror="this.onerror=null;this.src='https://tradeuphero.com/logo.png';"
    />
    <div id="float" v-if="showFloat">{{ float.toFixed(5) }}</div>
    <div id="price">{{ currency(price) }}</div>
    <div id="percentage" v-if="percentage">{{ percentage }}%</div>
  </div>
</template>

<script>
import { applyCurrency } from "../util";
export default {
  name: "ItemCard",
  props: {
    name: String,
    float: Number,
    statTrak: Boolean,
    isFullName: {
      type: Boolean,
      required: false,
      default: false,
    },
    percentage: {
      type: String,
      required: false,
      default: null,
    },
    selected: {
      type: Boolean,
      default: true,
      required: false,
    },
    showFloat: {
      type: Boolean,
      default: true,
      required: false,
    },
    removable: {
      type: Boolean,
      default: false,
      required: false,
    },
    inPayment: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    usedCurrency() {
      return this.inPayment ? "USD" : this.$store.state.currency;
    },
    usedCurrencyExchangeRate() {
      return this.inPayment ? 1 : this.$store.state.currencyExchangeRate;
    },
    image() {
      return `${
        process.env.VUE_APP_API_DOMAIN
      }/image/${this.getFullName()}/128fx128f`;
    },
    price() {
      if (
        this.$store.state.prices === null ||
        !(this.getFullName() in this.$store.state.prices)
      ) {
        return "";
      }

      return this.$store.state.prices[this.getFullName()].customMedian;
    },
  },
  methods: {
    getFullName() {
      if (this.isFullName) return this.name;
      const condition = this.floatToCondition();
      const itemType = this.statTrak ? "StatTrak™ " : "";
      return `${itemType}${this.name}${condition}`;
    },
    floatToCondition() {
      if (this.float < 0.07) return "(Factory New)";
      if (this.float < 0.15) return "(Minimal Wear)";
      if (this.float < 0.38) return "(Field-Tested)";
      if (this.float < 0.45) return "(Well-Worn)";
      return "(Battle-Scarred)";
    },
    currency(value) {
      return applyCurrency(
        value,
        this.usedCurrency,
        this.usedCurrencyExchangeRate
      );
    },
  },
};
</script>

<style lang="scss" scoped>
#item-card {
  width: 120px;
  height: 120px;
  background: #b9cfd8;
  border-radius: 5px;
  color: #cccccc;
  font-size: 12px;
  line-height: 12px;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  #overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(1, 42, 54, 0.55);
    backdrop-filter: blur(4px);
    z-index: 10;
    border-radius: 4px;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 25px;
      height: 25px;
    }
  }
  &.removable:hover {
    #overlay {
      opacity: 1;
    }
    cursor: pointer;
  }
  &.selected {
    background: #c1eeff;
    &.in-payment {
      background: #d8b2c5;
    }
  }
  #name {
    position: absolute;
    top: 5px;
    left: 5%;
    width: 90%;
    text-align: center;
    z-index: 5;
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #012a36;
  }
  #image {
    position: absolute;
    top: 10px;
    width: 90%;
    margin-left: 5%;
    z-index: 0;
  }
  #float {
    position: absolute;
    bottom: 20px;
    left: 5%;
    width: 90%;
    text-align: center;
    z-index: 5;
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #012a36;
  }
  #price {
    position: absolute;
    bottom: 5px;
    left: 5%;
    width: 90%;
    text-align: center;
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #8748fb;
  }
  #percentage {
    position: absolute;
    bottom: 40px;
    left: 20%;
    width: 60%;
    padding: 5px;
    box-sizing: border-box;
    text-align: center;
    background: rgba(1, 42, 54, 0.7);
    backdrop-filter: blur(3px);
    border-radius: 4px;
    font-family: Karla;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;

    color: #ffffff;
  }
}
</style>
