<template>
  <span>
    <img src="../assets/info.svg" />
    <div><slot></slot></div
  ></span>
</template>

<script>
export default {
  name: "Info",
};
</script>

<style lang="scss" scoped>
span {
  position: relative;
  margin-left: 5px;
  img {
    position: absolute;
    top: 2px;
    left: 0;
    width: 16px;
    height: 16px;
  }
  div {
    display: none;
    position: absolute;
    bottom: calc(100% + 10px);
    left: -30px;
    background-color: #d8b2c5;
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    border-radius: 4px;
    color: #012a36;
    padding: 5px;
    width: 180px;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 100%;
      left: 28px;
      width: 0;
      height: 0;
      border: solid 10px transparent;
      border-top: solid 10px #d8b2c5;
    }
  }
  &:hover div {
    display: block;
  }
}
</style>