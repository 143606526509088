import { render, staticRenderFns } from "./ItemCard.vue?vue&type=template&id=610514c0&scoped=true"
import script from "./ItemCard.vue?vue&type=script&lang=js"
export * from "./ItemCard.vue?vue&type=script&lang=js"
import style0 from "./ItemCard.vue?vue&type=style&index=0&id=610514c0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "610514c0",
  null
  
)

export default component.exports