<template>
  <div id="area">
    <div id="tradeups">
      <div id="header" v-if="searchParams.searching">Searching...</div>
      <div id="header" v-else-if="tradeUps.length > 0">Your search results</div>
      <div id="header" v-else>No profitable TradeUps found</div>
      <div id="filter-toggle" @click="showingFilter = !showingFilter">
        <img src="../assets/filter.svg" />
      </div>
      <template v-if="searchParams.searching">
        <TradeUp v-for="n in 10" :key="'A' + n" placeholder />
      </template>
      <template v-else-if="tradeUps.length > 0">
        <Teaser
          @upgrade="$emit('upgrade')"
          v-if="!subscribed"
          :percentage="Math.floor(mostProfitable)"
        />
        <template v-for="(tradeUp, n) in tradeUps">
          <TradeUp
            :key="tradeUp.uti"
            :tradeup="tradeUp"
            :favorite="favorites.includes(tradeUp.uti)"
          />
          <Ad v-if="!subscribed && n % 3 === 0" :key="n + 0.5" />
        </template>

        <button
          id="load-more"
          v-if="tradeUps.length < totalTradeUps - 1"
          @click="loadMore"
          v-observe-visibility="visibilityChanged"
        >
          {{ loadingMore ? "Loading" : "Load more" }}
          <span class="loading-dot" v-if="loadingMore">.</span>
          <span class="loading-dot" v-if="loadingMore">.</span>
          <span class="loading-dot" v-if="loadingMore">.</span>
        </button>
      </template>
    </div>
    <TradeUpFilter
      v-model="searchParams"
      v-on:updateTradeUps="loadInitial"
      :showing="showingFilter"
      @closePopup="showingFilter = false"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import TradeUp from "@/components/TradeUp.vue";
import TradeUpFilter from "@/components/TradeUpFilter.vue";
import { getTradeUps } from "../tradeUps.js";
import Teaser from "@/components/Teaser.vue";
import Ad from "@/components/Ad.vue";
import { getCookie } from "../util";

export default {
  components: {
    TradeUp,
    TradeUpFilter,
    Teaser,
    Ad,
  },
  name: "TradeUps",
  computed: {
    ...mapState({
      favorites: (state) => state.user.favorites,
      subscribed: (state) => state.user.subscribed,
    }),
  },

  data() {
    return {
      tradeUps: [],
      searchParams: {
        searchFrom: "Blue",
        difficulty: 50,
        price: 50,
        statTrak: false,
        filterUnter: false,
        filterBS: false,
        searching: false,
        minVolume: 10,
        url: process.env.VUE_APP_API_DOMAIN,
        input: "median",
        output: "median",
      },
      mostProfitable: 0,
      totalTradeUps: null,
      loadingMore: false,
      google: this.$gtag,
      showingFilter: false,
    };
  },
  methods: {
    visibilityChanged(isVisible) {
      if (isVisible && !this.loadingMore) {
        this.loadMore();
      }
    },
    changeSearchParams(e) {
      this.searchParams = e;
    },
    loadInitial() {
      if (this.searchParams.searching) return;

      this.searchParams.searching = true;
      this.tradeUps = [];

      this.fetchTradeUps(this.transformSearchParams()).then((data) => {
        this.tradeUps = data.tradeUps;
        this.totalTradeUps = data.total;
        this.mostProfitable = data.mostProfitable;
        this.searchParams.searching = false;
      });
    },
    loadMore() {
      if (
        this.tradeUps.length < this.totalTradeUps - 1 &&
        !this.searchParams.searching
      ) {
        this.loadingMore = true;
        this.fetchTradeUps(
          this.transformSearchParams(),
          this.tradeUps.length,
          10
        ).then((data) => {
          this.tradeUps = this.tradeUps.concat(data.tradeUps);
          this.loadingMore = false;
        });
      }
    },
    fetchTradeUps(searchParams, offset = 0, amount = 20) {
      return (this.$store.state.weaponsClient === null
        ? this.$store.dispatch("fetchWeaponsClient")
        : Promise.resolve()
      ).then(() =>
        getTradeUps(
          searchParams,
          offset,
          amount,
          this.$store.state.weaponsClient
        )
      );
    },
    transformSearchParams() {
      const sid = getCookie("connect.sid");

      const rarity = strToRarity(this.searchParams.searchFrom);

      const sp = JSON.parse(JSON.stringify(this.searchParams));
      sp.rarity = rarity;
      sp.sid = sid;
      return sp;
    },
  },
  created() {
    this.loadInitial();
    this.$store.dispatch("fetchFavorites");
  },
};

function strToRarity(str) {
  switch (str) {
    case "White":
      return 0;
    case "Lightblue":
      return 1;
    case "Blue":
      return 2;
    case "Purple":
      return 3;
    case "Pink":
      return 4;
  }
}
</script>

<style scoped lang="scss">
#area {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
}
#tradeups {
  width: calc(100% - 350px);
}
#no-profitable {
  width: 100%;
  text-align: center;
  font-size: 32px;
  color: #ccc;
  margin-top: 20px;
}
#load-more {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 50px;
  color: #ccc;
  font-size: 28px;
  border: none;
  border-radius: 10px;
}
#load-more:focus {
  outline: none;
}
#load-more:hover {
  cursor: pointer;
}

.loading-dot {
  animation: loading-dots 1.4s infinite;
  animation-fill-mode: both;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loading-dots {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

#header {
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  margin-bottom: 23px;
}

#filter-toggle {
  display: none;
}

@media only screen and (max-width: 1365px) {
  #tradeups {
    width: 100%;
  }
  #filter-toggle {
    display: block;
    right: 0;
    bottom: 10vh;
    position: fixed;
    background: rgba(235, 75, 75, 0.7);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
    img {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
