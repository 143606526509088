export function getCookie(cname) {
  const name = `${cname}=`;
  const ca = decodeURIComponent(document.cookie).split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function applyCurrency(value, currency, exchangeRate) {
  //* cent to $
  value /= 100;
  value *= exchangeRate;
  return value.toLocaleString(navigator.language, {
    style: "currency",
    currency,
  });
}
