var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"outer-shell"}},[_c('div',{class:{ alt: _vm.alternativeStyle, active: _vm.active },style:({ width: _vm.fullWidth ? '100%' : '' }),attrs:{"id":"wrapper"},on:{"click":function($event){_vm.active = !_vm.active}}},[_c('div',{attrs:{"id":"current"}},[_vm._v(" "+_vm._s(_vm.value)+" "),_c('img',{style:({ transform: _vm.active ? 'rotate(0)' : 'rotate(180deg)' }),attrs:{"src":require("../assets/chevron.svg")}})]),_c('div',{style:({
        height: _vm.active ? 'auto' : '0',
      }),attrs:{"id":"list"}},_vm._l((_vm.elements),function(item,index){return _c('div',{key:index,style:({
          backgroundColor:
            _vm.getText(item) === _vm.value ? 'rgba(0, 0, 0, 0.3)' : 'transparent',
          '--hover-color': 'rgba(0, 0, 0, 0.2)',
        }),on:{"click":function($event){return _vm.select(item)}}},[_vm._v(" "+_vm._s(_vm.getText(item))+" "),(item.locked)?_c('span',{attrs:{"id":"locked"}},[_vm._v("PRO")]):_vm._e()])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }