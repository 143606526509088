<template>
  <div id="area">
    <div id="navigation-toggle" @click="showingNavigation = true">
      <img src="../assets/navigation.svg" />
    </div>
    <div id="content" class="support-page-left">
      <div id="faqs" data-header="Frequently Asked Questions">
        <div
          class="faq"
          v-for="(item, index) in questions"
          :key="index"
          :id="index"
        >
          <h2>{{ item.question }}</h2>
          <p v-html="item.answer"></p>
        </div>
      </div>
      <div id="bugs" data-header="Known Bugs" v-html="knownBugs"></div>
      <div id="contact" data-header="Contact Support">
        <h2>Found a bug or need some help?</h2>
        <p>
          Just leave us a message below and we will get back to you on the given
          email address
        </p>
        <div id="inputs">
          <div class="line">
            <span>Name</span>
            <input v-model="name" placeholder="Your name or alias" />
          </div>
          <div class="line">
            <span>Email address</span>
            <input v-model="email" placeholder="Your email address" />
          </div>

          <textarea
            v-model="text"
            placeholder="type your message here..."
          ></textarea>
          <div class="checkbox-line">
            <Checkbox v-model="checked" />
            <span
              >I have read and accepted the privacy policy. I agree that my
              information and data will be collected and stored electronically
              to answer my inquiry.</span
            >
          </div>
          <button @click="submit">SEND MESSAGE</button>
        </div>
      </div>
    </div>

    <div id="right-box" :class="{ showing: showingNavigation }">
      <img id="x" src="../assets/redX.svg" @click="showingNavigation = false" />
      <a class="link" href="#faqs" @click="showingNavigation = false"
        >Frequently Asked Questions</a
      >
      <a
        v-for="(item, index) in questions"
        :key="index"
        :href="'#' + index"
        class="question"
        @click="showingNavigation = false"
        >{{ item.question }}</a
      >
      <a class="link" href="#bugs" @click="showingNavigation = false"
        >Known Bugs</a
      >
      <a class="link" href="#contact" @click="showingNavigation = false"
        >Contact Support</a
      >
    </div>
  </div>
</template>

<script>
import questions from "@/documents/faq.json";
import knownBugs from "raw-loader!@/documents/knownBugs.html";
import Checkbox from "@/components/Checkbox";
export default {
  name: "Support",
  components: { Checkbox },
  data() {
    return {
      questions,
      knownBugs,
      name: "",
      email: "",
      text: "",
      checked: false,
      showingNavigation: false,
    };
  },
  methods: {
    async submit() {
      if (this.name.length <= 0) {
        this.$toasted.show("Please let us know who you are :)");
        return;
      } 
      if (this.email.length <= 4) {
        this.$toasted.show(
          "You need to submit an email so we can contact you!"
        );
        return;
      }
      if (this.text.length <= 0) {
        this.$toasted.show("You need to write something!");
        return;
      } 
      if(!this.checked) {
        this.$toasted.show("You need to accept our privacy Policy!");
        return;
      }

      const success = await fetch(`${process.env.VUE_APP_API_DOMAIN}/support`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.email,
          name: this.name,
          message: this.text,
          privacy: this.checked
        }),
        credentials: "include",
      })
        .then((data) => data.json())
        .then((data) => {
          return data.success;
         
        })
        .catch((err) => {
          console.log(err);
          return false;
        });
      if (success) {
          this.$toasted.show("Your support request has been sent!");
          this.text = "";
        } else {
          this.$toasted.error(
            "Sending failed due to a server error, please try again"
          );
        }
    },
  },
};
</script>

<style scoped lang="scss">
#area {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
}
#content {
  width: 70%;
  #faqs,
  #bugs,
  #contact {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 100%;
    background: rgba(238, 250, 255, 0.2);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    padding: 20px;
    color: #ffffff;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 100px;
    position: relative;
    &::before {
      content: attr(data-header);
      position: absolute;
      bottom: calc(100% + 20px);
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;

      color: #ffffff;
      left: 0;
    }
  }
  #contact {
    #inputs {
      display: flex;
      flex-direction: column;
      align-items: center;
      .line {
        display: flex;
        flex-direction: column;
        width: 25%;
        margin-bottom: 30px;
        span {
          font-family: Karla;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          color: #bcc2ce;
        }
        input {
          background-color: transparent;
          border: none;
          border-bottom: 2px solid #c1eeff;
          font-family: Karla;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: #ffffff;
          &::placeholder {
            font-family: Karla;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #ffffff;
          }
        }
      }
      textarea {
        width: 50%;
        background-color: transparent;
        border: 2px solid #c1eeff;
        box-sizing: border-box;
        border-radius: 4px;
        min-height: 200px;
        padding: 11px 16px;
        font-family: Karla;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
        &::placeholder {
          font-family: Karla;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: #bcc2ce;
        }
        &:active {
          border: 2px solid #c1eeff;
        }
      }
      .checkbox-line {
        width: 50%;
        display: flex;
        margin-top: 20px;
        font-family: Karla;
      }
      button {
        background: #eb4b4b;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04),
          0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        font-family: Karla;
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        line-height: 20px;
        color: #012a36;
        padding: 15px 36px;
        border: none;
        margin-top: 50px;
        letter-spacing: 1px;
        transition: opacity 0.2s ease, box-shadow 0.2s ease;
        &:hover {
          cursor: pointer;
          opacity: 0.9;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}
#right-box {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  display: block;
  width: 25%;
  height: 100%;
  position: fixed;
  right: 2%;
  color: #c1eeff;
  margin-top: 30px;
  a {
    display: block;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;
    margin-left: 15px;
    font-family: Karla;
    &.link {
      font-weight: bold;
      font-size: 17px;
      line-height: 20px;
      margin-bottom: 6px;
      margin-left: 0;
      margin-top: 20px;
      &:first-of-type {
        margin-top: 0;
      }
    }
    &.question {
      margin-top: 8px;
    }
  }
}
h2 {
  font-family: Karla;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
}
p {
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #ffffff;
  a:visited {
    color: #c1eeff !important;
  }
}
a {
  color: #c1eeff;
  text-decoration: none;
}
:target {
  animation: highlight 1s ease-in-out;
  border-radius: 10px;
}
@keyframes highlight {
  0% {
    background-color: #d8b2c5;
  }
  100% {
    background-color: rgba(238, 250, 255, 0.2);
  }
}
.faq {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 10px;
}
#navigation-toggle {
  display: none;
}
#x {
  display: none;
}
@media only screen and (max-width: 1365px) {
  #content {
    width: 100%;
  }
  #right-box {
    display: none;
    &.showing {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: rgba(50, 92, 104, 0.95);
      margin-top: 0;
      padding: 90px 0px 17px 45px;
      z-index: 100;
      &::before {
        content: "Quick Navigation";
        font-family: Karla;
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        line-height: 20px;
        color: #ffffff;
        margin-bottom: 20px;
        display: block;
      }
      #x {
        display: block;
        position: absolute;
        top: 12px;
        right: 12px;
        width: 20px;
        height: 20px;
        padding: 10px;
        z-index: 100;
      }
    }
  }
  .line,
  .checkbox-line {
    width: 100% !important;
  }
  textarea {
    width: 100% !important;
  }
  #navigation-toggle {
    display: block;
    right: 0;
    bottom: 10vh;
    position: fixed;
    background: rgba(235, 75, 75, 0.7);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
    img {
      width: 20px;
      height: 20px;
    }
  }
}
</style>

<style lang="scss">
.support-page-left {
  a {
    color: #c1eeff;
  }
  h2.bug {
    font-family: Karla;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
  }
  p.bug,
  ul.bug {
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }
}
</style>
