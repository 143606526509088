<template>
  <div id="area">
    <div id="inventory-wrapper" :style="{ height: this.items.length > 0 ? 'calc(100vh - 600px)' : '100%' }">
      <div id="header" v-if="!inventory && !error">Loading...</div>
      <div id="header" v-else-if="error">{{ error }}</div>
      <div id="header" v-else>
        Your Tradeuppable Skins
        <img src="../assets/reload.svg" @click="reload" />
      </div>
      <div id="inventory">
        <template v-if="this.$store.state.prices !== null">
          <ItemCard v-for="(item, index) in inventory" :key="`${item.assetid}-desktop`" :name="item.name"
            :float="item.float" isFullName v-show="!items.includes(index) && item.float > 0"
            @remove="addToTradeup(index)" class="desktop"></ItemCard>
          <ItemCard v-for="item in inventory" :key="`${item.assetid}-mobile`" :name="item.name" :float="item.float"
            isFullName class="mobile"></ItemCard>
        </template>
        <div v-else id="placeholder"></div>
      </div>
    </div>
    <div id="calculator-area" v-if="this.items.length > 0">
      <div id="input-cards">
        <ItemCard v-for="index in items" :key="`${inventory[index].assetid}-calc`" :name="inventory[index].name"
          :float="inventory[index].float" isFullName removable @remove="removeFromTradeup(index)"></ItemCard>
        <div class="placeholder-card" v-for="n in 10 - this.items.length" :key="n"></div>
        <div v-if="this.items.length > 0" id="clear">
          <div @click="clear">Clear all</div>
        </div>
      </div>
      <div id="output-cards" v-if="tradeup">
        <ItemCard v-for="item in tradeup.results" :key="`${item.name}-result`" :name="item.name" :float="item.float"
          :percentage="item.percentage"></ItemCard>
      </div>
      <div id="tradeup-stats" v-if="tradeup !== null" :style="{
        '--profit-color':
          Number.parseInt(tradeup.profit.percent) > 0 ? '#4beb5b' : '#DF5151',
      }">
        <div id="percent">{{ tradeup.profit.percent }}</div>
        <div id="profit">{{ currency(tradeup.profit.total) }}</div>
        <div class="statline">
          <span>Cost: </span>
          <span>{{ currency(tradeup.cost) }}</span>
        </div>
        <div class="statline">
          <span>Value: </span>
          <span>{{ currency(tradeup.averageValue) }}</span>
        </div>
        <float-meter class="float" :max-float="tradeup.maxfloat" />
        <div class="statline">
          <span>MAX FLOAT: </span>
          <span>{{ tradeup.maxfloat }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemCard from "@/components/ItemCard.vue";
import { parseSingleItem, itemArrayToUtid } from "../tradeUps.js";
import { applyCurrency } from "../util";
export default {
  name: "Inventory",
  components: {
    ItemCard,
  },
  data() {
    return {
      inventory: null,
      items: [],
      tradeup: null,
      statTrak: false,
      rarity: null,
      skins: [],
      error: null,
    };
  },
  methods: {
    clear() {
      this.items = [];
      this.$forceUpdate();
      this.tradeup = null;
      this.rarity = null;
    },
    reload() {
      this.inventory = null;
      this.items = [];
      this.tradeup = null;
      this.statTrak = false;
      this.rarity = null;
      this.skins = [];
      this.error = null;
      this.load();
    },
    removeFromTradeup(index) {
      const itemsIndex = this.items.indexOf(index);
      this.items.splice(itemsIndex, 1);
      this.$forceUpdate();
      this.tradeup = null;
      if (this.items.length === 0) {
        this.rarity = null;
      }
    },
    addToTradeup(index) {
      if (this.items.length < 10) {
        let rarity;
        for (let i = 0; i < this.skins.length; i++) {
          if (
            this.skins[i].name ===
            this.removeCondition(this.inventory[index].name)
          ) {
            rarity = this.skins[i].rarity;
          }
        }
        if (this.rarity === null || this.rarity === rarity) {
          this.rarity = rarity;
          this.items.push(index);
          this.$forceUpdate();
          if (this.items.length === 10) this.calculate();
        }
      }
    },
    load() {
      fetch(`${process.env.VUE_APP_API_DOMAIN}/weapons.json`)
        .then((r) => r.json())
        .then((data) => {
          this.skins = data;
          fetch(
            `${process.env.VUE_APP_API_DOMAIN}/account/inventory/${this.$store.state.user.uid}/detailed`
          )
            .then((data) => data.json())
            .then((res) => {
              if (!res.success) {
                this.error = res.msg;
              } else {
                this.inventory = res.items;
              }
            });
        });
    },
    removeCondition(name) {
      return name
        .replace("(Factory New)", "")
        .replace("(Field-Tested)", "")
        .replace("(Minimal Wear)", "")
        .replace("(Well-Worn)", "")
        .replace("(Battle-Scarred)", "")
        .replace("StatTrak™ ", "");
    },
    currency(value) {
      return applyCurrency(
        value,
        this.$store.state.currency,
        this.$store.state.currencyExchangeRate
      );
    },
    calculate() {
      const usedItems = this.items.map((inventoryIndex) => {
        const item = this.inventory[inventoryIndex];
        return {
          float: item.float,
          name: this.removeCondition(item.name),
          rarity: item.rarity,
          statTrak: item.name.includes("™"),
        };
      });
      const utid = itemArrayToUtid(usedItems, this.itemIDs);
      fetch(`${process.env.VUE_APP_API_DOMAIN}/calculate/${utid}`)
        .then((response) => response.json())
        .then((data) => {
          const parsed = parseSingleItem(data.tradeUp, this.itemIDs);
          this.tradeup = parsed;
        })
        .catch((err) => {
          console.log(err);
          this.$toasted.show("Something went wrong...");
        });
    },
  },
  mounted() {
    Promise.all([
      this.$store.dispatch("fetchUser"),
      this.$store.dispatch("fetchPrices"),
    ]).then(() => {
      this.load();
    });
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  display: none;
}

#area {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}

#inventory-wrapper {
  width: 100%;
  justify-content: flex-start;
  box-sizing: border-box;
  position: relative;
  margin-top: 55px;

  #header {
    position: absolute;
    bottom: calc(100% + 10px);
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #ffffff;
    left: 0;
    display: flex;
    align-items: center;

    img {
      margin-left: 10px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

#inventory {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 120px));
  grid-auto-rows: 120px;
  grid-gap: 30px;
  background: rgba(238, 250, 255, 0.2);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;
  overflow-y: auto;
  height: 100%;

  &>div:hover {
    cursor: pointer;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

#placeholder {
  height: 60vh;
  width: 100%;
}

#calculator-area {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 50px;

  #input-cards {
    position: relative;
    display: grid;
    grid-template-columns: 120px 120px 120px 120px 120px;
    grid-template-rows: 120px 120px 20px;
    grid-row-gap: 15px;
    grid-column-gap: 15px;
    grid-template-areas:
      ". . . . ."
      ". . . . ."
      "clear clear clear clear clear";
    background: rgba(238, 250, 255, 0.2);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    padding: 15px;

    #item-card:hover {
      cursor: pointer;
    }

    &::before {
      content: "Input Skins";
      position: absolute;
      bottom: calc(100% + 10px);
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: #ffffff;
      left: 0;
    }

    #clear {
      font-family: Karla;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #c1eeff;
      text-decoration: underline;
      grid-area: clear;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      div:hover {
        cursor: pointer;
      }
    }
  }

  #output-cards {
    position: relative;

    background: rgba(238, 250, 255, 0.2);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;

    max-width: 600px;
    height: 320px;
    padding-right: 2px;
    width: 100px;
    flex-grow: 1;
    margin: 0 15px;

    #items-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, 120px);
      grid-template-rows: repeat(auto-fill, 120px);
      grid-row-gap: 15px;
      grid-column-gap: 15px;
      width: 100%;
      max-width: 600px;
      height: 100%;
      padding: 15px;
      box-sizing: border-box;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #012a36;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #012a36;
      }
    }

    &::before {
      content: "Output Skins";
      position: absolute;
      bottom: calc(100% + 10px);
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: #ffffff;
      left: 0;
    }
  }

  #tradeup-stats {
    position: relative;
    min-height: 265px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: rgba(238, 250, 255, 0.2);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    padding: 40px 82px;
    box-sizing: border-box;

    #percent,
    #profit {
      font-family: Karla;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
      color: var(--profit-color);
    }

    #profit {
      margin-bottom: 20px;
    }

    .float {
      margin-top: 10px;
    }

    .statline {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;

      &:last-of-type {
        font-size: 14px;
        line-height: 16px;
      }
    }

    &::before {
      content: "Results";
      position: absolute;
      bottom: calc(100% + 10px);
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: #ffffff;
      left: 0;
    }
  }

  #results {
    display: flex;
    flex-wrap: wrap;
  }

  .placeholder-card {
    width: 120px;
    height: 120px;
    background: #b9cfd8;
    border-radius: 5px;
  }
}

@media only screen and (max-width: 1365px) {
  .mobile {
    display: flex;
  }

  .desktop {
    display: none;
  }

  #calculator-area {
    display: none;
  }

  #inventory {
    height: auto;
  }
}
</style>
