<template>
  <div id="area">
    <div id="content">
      <div id="head-wrapper">
        <div id="head">
          <img id="avatar" :src="avatar" />
          <div id="header-info">
            <div id="name">{{ name }}</div>
            <div id="current-plan">Current Plan: {{ plan }}</div>
            <a
              v-if="subscribed"
              id="cancel"
              :href="cancelUrl"
              target="_blank"
              rel="noopener noreferrer"
              >cancel your subscription</a
            >
            <upgrade-button v-else @upgrade="$emit('upgrade')" id="upgrade" />
            <img id="upgrade" v-if="false" />
          </div>
        </div>
      </div>
      <div class="input-wrapper" id="currency">
        <span>Displayed Currency</span>
        <DropDown
          v-model="currentCurrency"
          :elements="currencies"
          class="drop"
          fullWidth
          alternativeStyle
        />
      </div>
      <div class="input-wrapper" id="steam-tax">
        <span>Exclude Steam tax from prices</span>
        <DropDown
          v-model="steamtax"
          :elements="['Yes', 'No']"
          class="drop"
          fullWidth
          alternativeStyle
        />
      </div>
      <div class="input-wrapper" id="email">
        <span>Contact Email</span>
        <input class="input" v-model="editEmail" />
      </div>
      <div class="input-wrapper" id="gumroad">
        <span>Gumroad subscription key</span>
        <input class="input" v-model="editGumroad" />
      </div>
      <div class="input-wrapper" id="trade-url">
        <span>Steam Trade-URL</span>
        <input class="input" v-model="editTradeUrl" />
      </div>
      <a id="logout" :href="logoutLink"
        ><img src="../assets/logout.svg" />Logout</a
      >
      <!--<div id="delete">Delete my account</div>-->
      <button id="save" @click="save">Save</button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DropDown from "@/components/DropDown";
import UpgradeButton from "./UpgradeButton.vue";
export default {
  name: "Account",
  components: { DropDown, UpgradeButton },
  data() {
    return {
      currentCurrency: this.$store.state.currency,
      currencies: ["EUR", "USD", "GBP", "AUD", "CAD"],
      steamtax: this.$store.state.user.excludeSteamTax ? "Yes" : "No",
      editEmail: this.$store.state.user.email,
      editGumroad: this.$store.state.user.gumroadKey,
      editTradeUrl: this.$store.state.user.tradeUrl,
      cancelUrl: `${process.env.VUE_APP_API_DOMAIN}/account/gumroad/cancel`,
    };
  },
  watch: {},
  computed: {
    plan() {
      return this.subscribed ? "Pro" : "Free";
    },
    logoutLink() {
      return `${process.env.VUE_APP_API_DOMAIN}/auth/logout`;
    },
    ...mapState({
      subscribed: (state) => state.user.subscribed,
      name: (state) => state.user.name,
      avatar: (state) => state.user.avatar,
    }),
  },
  methods: {
    save() {
      this.$store.dispatch("changeCurrency", this.currentCurrency);
      this.$store
        .dispatch("saveUserDetails", {
          email: this.editEmail,
          gumroadKey: this.editGumroad,
          tradeUrl: this.editTradeUrl,
          excludeSteamTax: this.steamtax === "Yes",
        })
        .then((success) => {
          if (success) this.$toasted.show("Changes Saved");
          else this.$toasted.error("Saving failed");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#area {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
#upgrade {
  margin-top: 10px;
}
#content {
  background: rgba(238, 250, 255, 0.2);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  position: relative;
  width: 70%;
  margin-top: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto 200px 200px;
  grid-template-areas:
    "header header header"
    "currency . steam-tax"
    "email gumroad-key trade-url";
  padding: 31px;
  box-sizing: border-box;
  &::before {
    content: "My Account";
    position: absolute;
    bottom: calc(100% + 20px);
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #ffffff;
  }
  #logout {
    position: absolute;
    top: 58px;
    right: 85px;
    display: flex;
    align-items: center;
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: #c1eeff;
    text-decoration: none;
    &:hover {
      cursor: pointer;
    }
    img {
      margin-right: 10px;
    }
  }
  #delete {
    position: absolute;
    left: 35px;
    bottom: 60px;
    font-family: Karla;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #eb4b4b;
    &:hover {
      cursor: pointer;
    }
  }
  #save {
    position: absolute;
    right: 75px;
    bottom: 50px;
    background: #eb4b4b;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: #012a36;
    padding: 9px 31px;
    border: none;
    letter-spacing: 1px;
    transition: opacity 0.2s ease, box-shadow 0.2s ease;
    &:hover {
      cursor: pointer;
      opacity: 0.9;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    }
  }
  #head-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    grid-area: header;
    margin-bottom: 100px;
    #head {
      display: flex;
      flex-direction: row;
      align-items: center;
      #avatar {
        width: 130px;
        height: 130px;
        border-radius: 65px;
        border: 4px solid #c1eeff;
        box-sizing: border-box;
        margin-right: 10px;
      }
      #header-info {
        #name {
          font-family: Karla;
          font-style: normal;
          font-weight: normal;
          font-size: 24px;
          line-height: 28px;
          color: #c1eeff;
        }
        #current-plan {
          font-family: Karla;
          font-style: normal;
          font-weight: normal;
          font-size: 17px;
          line-height: 20px;
          color: #ffffff;
        }
        #billing-cycle {
          font-family: Karla;
          font-style: normal;
          font-weight: normal;
          font-size: 17px;
          line-height: 20px;
          color: #ffffff;
        }
        #cancel {
          font-family: Karla;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          text-decoration-line: underline;
          color: #d8b2c5;
        }
      }
    }
  }
  #currency {
    grid-area: currency;
  }
  #steam-tax {
    grid-area: steam-tax;
  }
  #email {
    grid-area: email;
  }
  #gumroad {
    grid-area: gumroad-key;
  }
  #trade-url {
    grid-area: trade-url;
  }
  .input-wrapper {
    width: 60%;
    display: flex;
    flex-direction: column;
    margin-left: 20%;
    span {
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #bcc2ce;
      margin-bottom: 5px;
    }
    input {
      background-color: transparent;
      border: none;
      border-bottom: 2px solid #c1eeff;
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }
  }
}
@media only screen and (max-width: 1365px) {
  #area {
    padding-top: 100px;
  }
  #content {
    width: 100%;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    .input-wrapper {
      margin-bottom: 50px;
    }
    #save {
      bottom: 10px;
      right: 20px;
    }
    #logout {
      top: auto;
      bottom: 20px;
      right: auto;
      left: 20px;
    }
    &::before {
      display: none;
    }
    #head {
      flex-direction: column !important;
      #avatar {
        width: 80px !important;
        height: 80px !important;
      }
      #header-info {
        font-size: 17px !important;
        line-height: 20px !important;
      }
    }
  }
}
</style>
