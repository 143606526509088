import Vue from "vue";
import App from "./App.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faAngleDoubleUp,
  faBookmark,
  faCog,
  faQuestionCircle,
  faTimes,
  faNewspaper,
  faSignOutAlt,
  faSignInAlt,
  faCaretDown,
  faCaretUp,
  faListUl,
  faChartLine,
  faCalculator,
  faBug,
  faLightbulb,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
//import vueGtag from "vue-gtag";
import Fuse from "fuse.js";
import VueObserveVisibility from "vue-observe-visibility";
import Toasted from "vue-toasted";
import router from "./router";
import { store } from "./store";

library.add(
  faBars,
  faAngleDoubleUp,
  faBookmark,
  faCog,
  faQuestionCircle,
  faTimes,
  faNewspaper,
  faSignOutAlt,
  faSignInAlt,
  faCaretDown,
  faCaretUp,
  faListUl,
  faChartLine,
  faCalculator,
  faBug,
  faLightbulb,
  faTimesCircle
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.prototype.$search = function(term, list, options) {
  return new Promise(function(resolve) {
    var run = new Fuse(list, options);
    var results = run.search(term);
    resolve(results);
  });
};

/* Vue.use(
  vueGtag,
  {
    config: { id: "G-Q6FPBSW6HL" },
    bootstrap: false,
  },
  router
); */

Vue.use(Toasted, {
  duration: 3000,
  className: "toast",
});

Vue.use(VueObserveVisibility);
Vue.directive("observe-visibility", VueObserveVisibility.ObserveVisibility);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
