<template>
  <div id="wrapper">
    <a
      class="gumroad-button"
      href="https://gum.co/tradeuphero?wanted=true"
      target="_blank"
      >Subscribe via Gumroad</a
    >
    <div id="input-line">
      <span>Gumroad subscription key</span>
      <input v-model="key" placeholder="xxxxxxxx-xxxxxxxx-xxxxxxxx-xxxxxxxx" />
      <button @click="check">SUBMIT</button>
    </div>

    <div id="guide">
      <h3>How to subscribe:</h3>
      <ol>
        <li>Click the "Subscribe via Gumroad" Button</li>
        <li>Enter your payment Data and Confirm the transaction</li>
        <li>Click "View Content"</li>
        <li>
          Copy the License key into the input field above and click "Submit"
        </li>
        <li>
          You can also find your License key in your new gumroad account or in
          your email inbox
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: "Gumroad",
  data() {
    return {
      key: "",
    };
  },
  methods: {
    check() {
      const urlencoded = new URLSearchParams();
      urlencoded.append("gumroadKey", this.key);

      fetch(`${process.env.VUE_APP_API_DOMAIN}/account/gumroad/key`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: urlencoded,
        credentials: "include",
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.success) {
            this.$emit("nextStep");
            this.$gtag.event("purchase", {
              value: 7.99,
              event_label: "gumroad",
            });
          } else {
            this.$toasted.error("Invalid Gumroad key");
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="scss" scoped>
#wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  #input-line {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    span {
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #bcc2ce;
      margin-bottom: 5px;
    }
    input {
      background-color: transparent;
      border: none;
      border-bottom: 2px solid #c1eeff;
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      width: 200px;
      &::placeholder {
        color: #ccc;
      }
    }
    button {
      position: absolute;
      left: calc(100% + 20px);
      bottom: 0;
      font-family: Karla;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #012a36;
      padding: 7px 14px;
      background: #eb4b4b;
      border-radius: 2px;
      border: none;
      transition: opacity 0.2s ease, box-shadow 0.2s ease;
      &:hover {
        cursor: pointer;
        opacity: 0.9;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
      }
    }
  }
  #guide {
    width: 80%;
    background: rgba(138, 200, 218, 0.25);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 13px 10px;
    h3 {
      margin: 0;
      font-family: Karla;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
      color: #eb4b4b;
    }
    ol {
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }
  }
}
@media only screen and (max-width: 1365px) {
  button {
    position: relative !important;
    left: auto !important;
    margin-top: 10px;
  }
}
</style>



