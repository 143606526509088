<template>
  <div
    id="switch-wrapper"
    @click="toggle"
    :style="{ backgroundColor: active ? activeTrackColor : trackColor }"
  >
    <div
      id="handle"
      :style="{ left: active ? '20px' : '0', backgroundColor: handleColor }"
    >
      <div
        id="handle-animation"
        :class="initiated ? (active ? 'animation' : 'animation2') : ''"
        :style="{ backgroundColor: handleColor }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomSwitch",
  props: {
    handleColor: String,
    trackColor: String,
    activeTrackColor: String,
    active: Boolean,
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  model: {
    prop: "active",
    event: "toggle",
  },
  methods: {
    toggle: function() {
      if (!this.disabled) {
        this.$emit("toggle", !this.active);
        this.initiated = true;
      } else {
        this.$emit("tried-toggle");
      }
    },
  },
  data() {
    return {
      initiated: false,
    };
  },
};
</script>

<style scoped>
#switch-wrapper {
  width: 40px;
  height: 10px;
  position: relative;
  border-radius: 5px;
  transition: background-color 0.1s ease-in-out;
  margin: 5px;
}
#switch-wrapper:hover {
  cursor: pointer;
}
#handle {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  position: absolute;
  top: -5px;
  transition: left 0.1s ease-in-out;
  z-index: 10;
}
#handle-animation {
  position: absolute;
  left: 10px;
  top: 10px;
  opacity: 0;
  width: 0;
  height: 0;
  border-radius: 500px;
  z-index: 5;
  pointer-events: none;
}
.animation {
  animation: splash 0.5s;
}
.animation2 {
  animation: splash2 0.5s;
}
@keyframes splash {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 160px;
    height: 160px;
    left: -70px;
    top: -70px;
  }
}
@keyframes splash2 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 160px;
    height: 160px;
    left: -70px;
    top: -70px;
  }
}
</style>
