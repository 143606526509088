<template>
  <div id="cookie" v-if="!accepted">
    <h1 id="header">We use cookies</h1>
    <p>
      TradeUpHero uses multiple kinds of Cookies: Technical and functional
      Cookies needed for the website to function properly. Furthermore, we use
      tracking cookies to figure out how our users navigate the site, in order
      to improve your experience.
    </p>
    <p>
      By clicking "Agree to all", you agree with our usage of all Cookies. Under
      "Settings", you can choose which Cookies you want to allow.
    </p>
    <div id="settings-box" v-if="settingsShown">
      <div>
        <CustomSwitch
          v-model="essential"
          handle-color="#012A36"
          track-color="#B0B3B7"
          active-track-color="#EB4B4B"
          disabled
          @tried-toggle="$toasted.show('You can not disable essential cookies')"
        />
        Essential Cookies
      </div>
      <div>
        <CustomSwitch
          v-model="tracking"
          handle-color="#012A36"
          track-color="#B0B3B7"
          active-track-color="#EB4B4B"
        />
        Tracking Cookies
      </div>
    </div>
    <p id="cookie-info">
      <img src="../assets/chevronRight.svg" /> Learn more about this in our
      <router-link to="privacy#cookies">Cookie Policy</router-link>
    </p>
    <div id="buttons">
      <button
        id="settings"
        class="cookie-button"
        @click="settingsShown = true"
        v-if="!settingsShown"
      >
        Settings
      </button>
      <button id="save" class="cookie-button" @click="saveSelected" v-else>
        Save selected
      </button>
      <button id="agree" class="cookie-button" @click="agreeToAll">
        Agree to all
      </button>
    </div>
  </div>
</template>

<script>
import { bootstrap } from "vue-gtag";
import CustomSwitch from "@/components/Switch";
import { getCookie } from "../util";
export default {
  name: "CookiePopup",
  components: {
    CustomSwitch,
  },
  data() {
    return {
      essential: true,
      tracking: false,
      accepted: true,
      settingsShown: false,
    };
  },
  methods: {
    getDateNextYear() {
      const d = new Date();
      d.setFullYear(d.getFullYear() + 1);
      return d.toUTCString();
    },
    saveSelected() {
      const expirationDateStr = this.getDateNextYear();
      this.accepted = true;
      document.cookie = `tracking=false; path=/; expires=${expirationDateStr}`;
      document.cookie = `cookies=true; path=/; expires=${expirationDateStr}`;
    },
    agreeToAll() {
      const expirationDateStr = this.getDateNextYear();
      this.accepted = true;
      document.cookie = `tracking=true; path=/; expires=${expirationDateStr}`;
      document.cookie = `cookies=true; path=/; expires=${expirationDateStr}`;
      bootstrap();
    },
  },
  async created() {
    if (getCookie("cookies") === "true") {
      if (getCookie("tracking") === "true") {
        try {
          await bootstrap();
        } catch (e) {
          console.error(e);
        }
      }
    } else {
      this.accepted = false;
    }
  },
};
</script>

<style scoped lang="scss">
#cookie {
  position: fixed;
  bottom: 30px;
  max-height: calc(100vh - 60px);
  overflow-y: auto;
  left: calc(50% - 450px);
  width: 900px;
  z-index: 100;
  background: #d8b2c5;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 20px 40px 20px 40px;
  box-shadow: 0px 12px 50px rgba(60, 60, 86, 0.4),
    5px 20px 20px rgba(60, 60, 86, 0.25);
  #header {
    font-family: Karla;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #012a36;
  }
  p {
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #012a36;
  }
  #settings-box {
    margin-top: 40px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-evenly;
    div {
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #012a36;
      margin-left: 20px;
      margin-right: 40px;
      display: flex;
      align-items: center;
    }
  }
  #cookie-info {
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #012a36;

    img {
      margin-right: 5px;
    }
    a {
      color: #eb4b4b;
      font-weight: bold;
    }
  }
  #buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    #agree {
      background: #eb4b4b;
      border-radius: 4px;
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #012a36;
      padding: 7px 25px;
      border: none;
      margin-left: 27px;
      transition: opacity 0.2s ease, box-shadow 0.2s ease;
      &:hover {
        cursor: pointer;
        opacity: 0.9;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
      }
    }
    #settings,
    #save {
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #012a36;

      border: none;
      background-color: transparent;
      border-bottom: 1px solid #eb4b4b !important;
      border-radius: 0 !important;
      padding-bottom: 0px;
    }
  }
}

.cookie-button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 1365px) {
  #cookie {
    padding-top: 200px;
    width: 90%;
    left: 5%;
    bottom: 0;
    margin-bottom: 5%;
    height: auto;
    padding: 20px;
  }
  #settings,
  #save,
  #agree {
    font-size: 32px;
    padding: 5px 30px;
    border-radius: 30px;
  }
  #cookie-info {
    line-height: 18px;
    img {
      height: 16px;
    }
  }
  #cookie p {
    font-size: 18px !important;
    line-height: 20px;
  }
  #settings-box div {
    font-size: 36px;
    line-height: 42px;
    color: #000000;
    margin-left: 60px;
    margin-right: 120px;
    margin-bottom: 10px;
  }
  #settings-box {
    flex-direction: column;
  }
}
</style>
