<template>
  <div id="item-picker-wrapper">
    <div id="name">
      <span>Item Search</span>
      <input
        v-model="name"
        @blur="blur"
        @focus="active = true"
        placeholder="Name"
        id="name-input"
      />
      <div id="name-list">
        <div
          v-show="active"
          v-for="name in results"
          :key="name"
          @click="setName(name)"
        >
          {{ name }}
        </div>
      </div>
    </div>
    <div id="slider-wrapper">
      <div id="marker-area">
        <div
          class="marker"
          :style="{
            position: 'absolute',
            left: ' 0% ',
            top: '18px',
          }"
          data-float="0"
        ></div>
        <div
          class="marker"
          :style="{
            position: 'absolute',
            left: 'calc( 7% - 1px )',
            top: '18px',
          }"
          data-float="0.07"
        ></div>
        <div
          class="marker"
          :style="{
            position: 'absolute',
            left: 'calc( 15% - 1px )',
            top: '18px',
          }"
          data-float="0.15"
        ></div>
        <div
          class="marker"
          :style="{
            position: 'absolute',
            left: 'calc( 38% - 1px )',
            top: '18px',
          }"
          data-float="0.38"
        ></div>
        <div
          class="marker"
          :style="{
            position: 'absolute',
            left: 'calc(45% - 1px)',
            top: '18px',
          }"
          data-float="0.45"
        ></div>
        <div
          class="marker"
          :style="{
            position: 'absolute',
            left: 'calc( 100% - 1px )',
            top: '18px',
          }"
          data-float="1"
        ></div>
      </div>
      <Slider
        v-model="float"
        id="slider"
        min="0"
        max="1"
        :step="0.001"
        valueAbove
        :floor="ceilFloor.floor"
        :ceil="ceilFloor.ceil"
        value-name="Float:"
        track-color="#C1EEFF"
        handle-color="#EB4B4B"
      ></Slider>
      <div id="switch">
        <CustomSwitch
          handle-color="#EB4B4B"
          track-color="#B0B3B7"
          active-track-color="#C1EEFF"
          v-model="statTrak"
        /><span>StatTrak™</span>
      </div>
    </div>

    <button @click="add">ADD TO INPUTS</button>
  </div>
</template>

<script>
import debounce from "@/debounce.js";
import Slider from "@/components/Slider.vue";
import CustomSwitch from "@/components/Switch.vue";
export default {
  name: "ItemPicker",
  components: {
    Slider,
    CustomSwitch,
  },
  props: {},
  data() {
    return {
      name: "",
      names: [],
      results: [],
      active: false,
      float: 0,
      statTrak: false,
    };
  },
  computed: {
    ceilFloor() {
      if (this.name == "") return { ceil: null, floor: null };

      const weapon = this.$store.state.weaponsClient.find(
        (w) => w.name === this.name
      );

      if (weapon === undefined) return { ceil: null, floor: null };

      return {
        ceil: weapon.floatHigh / 100000,
        floor: weapon.floatLow / 100000,
      };
    },
  },
  methods: {
    setName(name) {
      this.name = name;
    },
    blur() {
      setTimeout(() => {
        this.active = false;
      }, 100);
    },
    add() {
      const weapon = this.$store.state.weaponsClient.find(
        (w) =>
          w.name === this.name &&
          w.floatHigh / 100000 >= this.float &&
          w.floatLow / 100000 <= this.float
      );

      if (weapon !== undefined) {
        this.$emit("add", {
          name: this.name,
          float: this.float,
          rarity: weapon.rarity,
          statTrak: this.statTrak,
        });
      }
    },
  },
  watch: {
    name: debounce(function () {
      this.$search(this.name, this.names, {
        shouldSort: true,
        threshold: 0.4,
        minMatchCharLength: 1,
      }).then((results) => {
        this.results = results.map((i) => i.item).slice(0, 5);
      });
    }, 300),
  },
  mounted() {
    this.$store.dispatch("fetchWeaponsClient").then(() => {
      this.names = this.$store.state.weaponsClient
        .filter((w) => w.rarity <= 4)
        .map((w) => w.name);
    });
  },
};
</script>

<style lang="scss">
#item-picker-wrapper {
  width: 100%;
  #slider-wrapper {
    width: 100%;
    position: relative;
    margin-bottom: 10px;
    #slider {
      z-index: 20;
      #input-wrapper input {
        font-size: 14px;
      }
    }
    #marker-area {
      width: calc(100% - 18px);
      box-sizing: border-box;
      margin-left: 10px;
      position: absolute;
      top: 25px;
      left: 0;
      z-index: 0;
      .marker {
        height: 20px;
        width: 2px;
        background-color: #fff;
        &::after {
          content: attr(data-float);
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2px;
          top: 105%;
          color: white;
          font-size: 12px;
        }
      }
    }
  }

  #name {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    span {
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #bcc2ce;
    }
    #name-input {
      width: 100%;
      box-sizing: border-box;
      height: 18px;
      border: none;
      background: transparent;
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      padding-left: 0;
      border-bottom: 2px solid #c1eeff;
      margin-top: 5px;
      outline: none;
      &::placeholder {
        color: #fff;
      }
    }
    #name-list {
      position: absolute;
      top: 100%;
      left: 0;
      background-color: #c1eeff;
      color: #012a36;
      font-family: Karla;
      min-width: 100%;
      z-index: 50;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04),
        0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
      border-radius: 0px 0px 4px 4px;
      overflow: hidden;
      div {
        box-sizing: border-box;
        width: 100%;
        padding: 2px;
        &:hover {
          background: #aed2df;
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  #switch {
    width: 200px;
    margin-left: calc(50% - 100px);
    margin-top: 70px;
    margin-bottom: -70px;
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    font-family: Karla;
  }

  button {
    background: #eb4b4b;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    border: none;
    font-family: Karla;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    color: #012a36;
    width: 200px;
    height: 50px;
    margin-left: calc(50% - 100px);
    margin-top: 80px;
    opacity: 1;
    letter-spacing: 1px;
    transition: opacity 0.2s ease, box-shadow 0.2s ease;
    &:hover {
      cursor: pointer;
      opacity: 0.9;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    }
  }
}

@media only screen and (max-width: 1365px) {
  #item-picker-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    box-sizing: border-box;
    padding: 0 10px;
  }
}
</style>
