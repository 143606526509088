<template>
  <img src="../assets/upgradeButton.svg" @click="$emit('upgrade')" />
</template>

<script>
export default {
  name: "UpgradeButton",
};
</script>

<style scoped lang="scss">
img {
  padding: 3px 19px;
  background-color: #8748fb;
  border-radius: 4px;
  box-shadow: 0px 0px 0px 0px #d8b2c5;
  transition: box-shadow 0.1s ease-in-out;
  &:hover {
    cursor: pointer;
    box-shadow: -5px 5px 0px 2px #d8b2c5;
  }
}
</style>