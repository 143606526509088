<template>
  <div id="outer-wrapper" :class="{ showing: showing }">
    <div id="header">Search Settings</div>
    <img id="x" src="../assets/redX.svg" @click="$emit('closePopup')" />
    <div id="wrapper">
      <h3>
        Input Rarity
        <Info
          >The input rarity is the rarity of the skins used in the TradeUp</Info
        >
      </h3>
      <DropDown
        v-model="searchParams.searchFrom"
        v-bind:elements="searchFromOptions"
        full-width
      />

      <h3>
        Pricing<Info
          >You can chose a pricing source for both input and output skins.<br />-
          Median: Steams Median pricing<br />- Buyorder: Highest current
          buyorder<br />- Lowest: Lowest currently on Steam market<br />-
          CustomMedian: Our own calculated median. Most accurate.</Info
        >
      </h3>
      <h4>Input</h4>
      <DropDown
        v-model="searchParams.input"
        :elements="pricingOptions"
        full-width
      />
      <h4 id="output-label">Output</h4>
      <DropDown
        v-model="searchParams.output"
        :elements="pricingOptions"
        full-width
      />

      <h3>
        Max Cost
        <Info>Maximum cost for shown TradeUps</Info>
      </h3>
      <CustomNumberInput v-model="searchParams.price" :locked="!subscribed" />

      <h3>
        Min Volume
        <Info
          >Minimum 24h Volume on the Steam community marked for used skins</Info
        >
      </h3>
      <CustomNumberInput
        v-model="searchParams.minVolume"
        :locked="!subscribed"
      />

      <h3 id="difficulty-label">
        Difficulty
        <Info
          >How difficult the skins are to find. The higher the difficulty, the
          rarer it is to find the necessary float values.</Info
        >
      </h3>
      <Slider
        :style="{ width: '100%' }"
        min="1"
        max="100"
        initial="50"
        v-model="searchParams.difficulty"
        :locked="!subscribed"
      />
      <br />
      <div class="switch-line">
        <CustomSwitch
          handle-color="#012A36"
          active-track-color="#F44F4F"
          track-color="#B0B3B7"
          v-model="searchParams.statTrak"
        />
        <span>StatTrak™</span>
      </div>

      <div class="switch-line">
        <CustomSwitch
          handle-color="#012A36"
          active-track-color="#F44F4F"
          track-color="#B0B3B7"
          v-model="searchParams.filterUnter"
          :disabled="!subscribed"
          @tried-toggle="$toasted.show('Sorry, this is a PRO feature')"
        />
        <span>Exclude under $0.03</span>
      </div>
      <div class="switch-line">
        <CustomSwitch
          handle-color="#012A36"
          active-track-color="#F44F4F"
          track-color="#B0B3B7"
          v-model="searchParams.filterBS"
          :disabled="!subscribed"
          @tried-toggle="$toasted.show('Sorry, this is a PRO feature')"
        />
        <span>Exclude Battle-scarred</span>
      </div>

      <br />

      <button @click="updateTradeUps">
        {{ buttonText }}
        <span v-if="searchParams.searching">
          <span class="loading-dot">.</span>
          <span class="loading-dot">.</span>
          <span class="loading-dot">.</span>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DropDown from "@/components/DropDown";
import CustomSwitch from "@/components/Switch";
import Slider from "@/components/Slider";
import Info from "@/components/Info";
import CustomNumberInput from "@/components/CustomNumberInput";

export default {
  name: "TradeUpFilter",
  components: { Slider, CustomSwitch, DropDown, CustomNumberInput, Info },
  props: { searchParams: Object, showing: Boolean },
  data() {
    return {
      searchFromOptions: ["White", "Lightblue", "Blue", "Purple", "Pink"],
    };
  },
  model: {
    prop: "searchParams",
    event: "change",
  },
  watch: {
    searchParams() {
      this.$emit("change", this.searchParams);
    },
  },
  methods: {
    changeStatTrak: function (statTrak) {
      this.searchParams.statTrak = statTrak;
    },
    changeFilterUnter: function (filterUnter) {
      this.searchParams.filterUnter = filterUnter;
    },
    changeFilterBS: function (filterBS) {
      this.searchParams.filterBS = filterBS;
    },
    changeDifficulty: function (difficulty) {
      this.searchParams.difficulty = difficulty;
    },
    changePrice: function (price) {
      this.searchParams.price = price;
    },
    changeMinVolume: function (minVol) {
      this.searchParams.minVolume = minVol;
    },
    updateTradeUps: function () {
      this.$emit("updateTradeUps");
      this.$emit("closePopup");
    },
  },
  computed: {
    buttonText: function () {
      return this.searchParams.searching ? "Loading" : "Search TradeUps";
    },
    pricingOptions() {
      if (this.subscribed) {
        return [
          { text: "buyOrder", locked: false },
          { text: "lowest", locked: false },
          { text: "median", locked: false },
          { text: "customMedian", locked: false },
        ];
      } else
        return [
          { text: "buyOrder", locked: true },
          { text: "lowest", locked: true },
          { text: "median", locked: false },
          { text: "customMedian", locked: true },
        ];
    },
    ...mapState({
      subscribed: (state) => state.user.subscribed,
    }),
  },
};
</script>

<style scoped lang="scss">
#difficulty-label {
  margin-bottom: 5px;
}
#output-label {
  margin-top: 15px;
}
#outer-wrapper {
  position: fixed;
  top: 160px;
  right: 50px;
  height: calc(100vh - 160px);
  #header {
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #ffffff;
    margin-bottom: 23px;
  }
}
#wrapper {
  box-sizing: border-box;

  width: 300px;
  background: rgba(238, 250, 255, 0.2);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding: 25px 50px 25px 50px;
  color: #ddd;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100% - 55px);
}

.switch {
  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

p {
  margin-block-start: 5px;
  margin-block-end: 5px;
}

h3 {
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 5px;
}

h4 {
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #d8b2c5;
  margin: 0;
}

button {
  background: #eb4b4b;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  width: 100%;
  height: 50px;
  font-family: Karla;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: #012a36;
  border: none;
  margin-top: 20px;
  letter-spacing: 1px;
  transition: opacity 0.2s ease, box-shadow 0.2s ease;
  padding: 10px 0px;
}

button:hover {
  cursor: pointer;
  opacity: 0.9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

button:focus {
  outline: none;
}

.switch-line {
  width: 100%;
  display: flex;
  margin-top: 15px;
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #ffffff;
}

.loading-dot {
  animation: loading-dots 1.4s infinite;
  animation-fill-mode: both;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loading-dots {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
#x {
  display: none;
}

@media only screen and (max-width: 1365px) {
  #outer-wrapper {
    display: none;
    background: rgba(50, 92, 104, 0.85);
    z-index: 1000000000;
    overflow-y: auto;
    &.showing {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      #header {
        display: none;
      }
      #wrapper {
        width: 100%;
        padding: 20px 50px 10px 80px;
        background-color: transparent;
        #input-wrapper {
          width: auto;
        }
        button {
          margin: 0;
        }
      }
    }
    #x {
      display: block;
      position: absolute;
      top: 12px;
      right: 12px;
      width: 20px;
      height: 20px;
      padding: 10px;
      z-index: 100;
    }
  }
}
</style>
