var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"item-picker-wrapper"}},[_c('div',{attrs:{"id":"name"}},[_c('span',[_vm._v("Item Search")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],attrs:{"placeholder":"Name","id":"name-input"},domProps:{"value":(_vm.name)},on:{"blur":_vm.blur,"focus":function($event){_vm.active = true},"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}}),_c('div',{attrs:{"id":"name-list"}},_vm._l((_vm.results),function(name){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active),expression:"active"}],key:name,on:{"click":function($event){return _vm.setName(name)}}},[_vm._v(" "+_vm._s(name)+" ")])}),0)]),_c('div',{attrs:{"id":"slider-wrapper"}},[_c('div',{attrs:{"id":"marker-area"}},[_c('div',{staticClass:"marker",style:({
          position: 'absolute',
          left: ' 0% ',
          top: '18px',
        }),attrs:{"data-float":"0"}}),_c('div',{staticClass:"marker",style:({
          position: 'absolute',
          left: 'calc( 7% - 1px )',
          top: '18px',
        }),attrs:{"data-float":"0.07"}}),_c('div',{staticClass:"marker",style:({
          position: 'absolute',
          left: 'calc( 15% - 1px )',
          top: '18px',
        }),attrs:{"data-float":"0.15"}}),_c('div',{staticClass:"marker",style:({
          position: 'absolute',
          left: 'calc( 38% - 1px )',
          top: '18px',
        }),attrs:{"data-float":"0.38"}}),_c('div',{staticClass:"marker",style:({
          position: 'absolute',
          left: 'calc(45% - 1px)',
          top: '18px',
        }),attrs:{"data-float":"0.45"}}),_c('div',{staticClass:"marker",style:({
          position: 'absolute',
          left: 'calc( 100% - 1px )',
          top: '18px',
        }),attrs:{"data-float":"1"}})]),_c('Slider',{attrs:{"id":"slider","min":"0","max":"1","step":0.001,"valueAbove":"","floor":_vm.ceilFloor.floor,"ceil":_vm.ceilFloor.ceil,"value-name":"Float:","track-color":"#C1EEFF","handle-color":"#EB4B4B"},model:{value:(_vm.float),callback:function ($$v) {_vm.float=$$v},expression:"float"}}),_c('div',{attrs:{"id":"switch"}},[_c('CustomSwitch',{attrs:{"handle-color":"#EB4B4B","track-color":"#B0B3B7","active-track-color":"#C1EEFF"},model:{value:(_vm.statTrak),callback:function ($$v) {_vm.statTrak=$$v},expression:"statTrak"}}),_c('span',[_vm._v("StatTrak™")])],1)],1),_c('button',{on:{"click":_vm.add}},[_vm._v("ADD TO INPUTS")])])
}
var staticRenderFns = []

export { render, staticRenderFns }