import Vue from "vue";
import VueRouter from "vue-router";
import TradeUps from "@/components/TradeUps.vue";
import Calculator from "@/components/Calculator.vue";
import Favorites from "@/components/Favorites.vue";
import Inventory from "@/components/Inventory.vue";
import NotFound from "@/components/NotFound.vue";
import Account from "@/components/Account.vue";
import Legal from "@/components/Legal.vue";
import Imprint from "raw-loader!@/documents/imprint.html";
import Tos from "raw-loader!@/documents/tos.html";
import Privacy from "raw-loader!@/documents/privacy.html";
import Support from "@/components/Support.vue";
import Subscribe from "@/components/Subscribe.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "TradeUps",
    component: TradeUps,
  },
  {
    path: "/calculator",
    name: "Calculator",
    component: Calculator,
  },
  {
    path: "/favorites",
    name: "Favorites",
    component: Favorites,
  },
  {
    path: "/inventory",
    name: "Inventory",
    component: Inventory,
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
  },
  {
    path: "/subscribe",
    name: "Subscribe",
    component: Subscribe,
  },
  {
    path: "/legalnotice",
    name: "LegalNotice",
    component: Legal,
    props: {
      header: "Legal Notice",
      text: Imprint,
    },
  },
  {
    path: "/tos",
    name: "TermsOfService",
    component: Legal,
    props: {
      header: "Terms of Service",
      text: Tos,
    },
  },
  {
    path: "/privacy",
    name: "PrivacyPolicy",
    component: Legal,
    props: {
      header: "Privacy Policy",
      text: Privacy,
    },
  },
  {
    path: "/index",
    redirect: "/",
  },
  {
    path: "/ref/:id",
    beforeEnter(to) {
      window.location.href = `https://gumroad.com/a/${to.params.id}`;
    },
  },
  {
    path: "/404",
    name: "404",
    component: NotFound,
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return { selector: to.hash };
    }

    return { x: 0, y: 0 };
  },
});

export default router;
