<template>
  <div id="wrapper">
    <h2>Sign up</h2>
    <p>
      To sign up, please enter your Email address and accept our Privacy Policy
      and Terms and Conditions.
    </p>
    <div id="email">
      <span>Email address</span>
      <input v-model="email" />
    </div>
    <div class="checkbox-line">
      <Checkbox v-model="privacy" color="#004356" width="15px" height="15px" />
      <span>I have read and accepted the <a>Privacy Policy.</a></span>
    </div>
    <div class="checkbox-line">
      <Checkbox v-model="tos" color="#004356" width="15px" height="15px" />
      <span>I have read and accepted the <a>Terms and Conditions.</a></span>
    </div>
    <div class="checkbox-line">
      <Checkbox v-model="updates" color="#004356" width="15px" height="15px" />
      <span>I want to receive updates and offers.</span>
    </div>
    <div id="error" v-if="errors.length > 0">
      <div v-for="error in errors" :key="error">{{ error }}</div>
    </div>
    <div id="buttons">
      <button id="logout" @click="logout">Log out</button>
      <button id="signup" @click="signup">Sign Up</button>
    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/Checkbox.vue";
export default {
  name: "Signup",
  components: { Checkbox },
  data() {
    return {
      email: "",
      privacy: false,
      tos: false,
      updates: false,
      errors: [],
    };
  },
  methods: {
    signup() {
      const errors = [];
      if (
        this.email.length < 6 ||
        !this.email.includes("@") ||
        !this.email.includes(".")
      ) {
        errors.push("You need to enter a valid Email");
      }
      if (!this.privacy) {
        errors.push("You need to accept our Privacy Policy");
      }
      if (!this.tos) {
        errors.push("You need to accept our Terms and Conditions");
      }

      this.errors = errors;
      if(errors.length > 0) {
        return;
      }

      fetch(`${process.env.VUE_APP_API_DOMAIN}/account/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.email,
          privacy: this.privacy,
          tos: this.tos,
          updates: this.updates,
        }),
        credentials: "include",
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.success) {
            this.$emit("accepted");
            this.$toasted.show("Success! Welcome to TradeUpHero!");
            this.$gtag.event("sign_up");
          } else {
            this.$toasted.error("Something went wrong");
          }
        })
        .catch((err) => console.log(err));
    },
    logout() {
      window.location.href = `${process.env.VUE_APP_API_DOMAIN}/auth/logout`;
    },
  },
};
</script>

<style lang="scss" scoped>
#wrapper {
  position: fixed;
  top: 10vh;
  left: 30vw;
  width: 40vw;
  z-index: 100;
  min-height: 30vh;
  background: #d8b2c5;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding: 30px 75px;
  box-sizing: border-box;
  #email {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    span {
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #004356;
    }
    input {
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #012a36;
      border: none;
      background-color: transparent;
      border-bottom: 2px solid #004356;
    }
  }
  h2 {
    width: 100%;
    text-align: center;
    font-family: Karla;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
  }
  p {
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #012a36;
    margin-bottom: 60px;
  }
  .checkbox-line {
    display: flex;
    max-width: 300px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    span {
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #012a36;
    }
  }
  #buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    #logout {
      background-color: transparent;
      border: none;
      margin-right: 50px;
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #012a36;
      &:hover {
        cursor: pointer;
      }
    }
    #signup {
      background: #eb4b4b;
      border-radius: 4px;
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 20px;
      color: #012a36;
      padding: 10px 45px;
      border: none;
      transition: opacity 0.2s ease, box-shadow 0.2s ease;
      &:hover {
        cursor: pointer;
        opacity: 0.9;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
      }
    }
  }
  #error {
    border-radius: 4px;
    max-width: 300px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-family: Karla;
    background-color: #eb4b4b77;
    border: solid 2px #eb4b4bcc;
    padding: 5px;
    box-sizing: border-box;
    color: #333;
    margin-bottom: 10px;
    div {
      margin-bottom: 5px;
    }
  }
}
@media only screen and (max-width: 1365px) {
  #wrapper {
    width: 90%;
    left: 5%;
    top: auto;
    bottom: 30px;
  }
  #signup {
    padding: 10px 20px !important;
  }
}
</style>
