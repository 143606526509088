<template>
  <div id="outer-shell">
    <div
      id="wrapper"
      @click="active = !active"
      :style="{ width: fullWidth ? '100%' : '' }"
      :class="{ alt: alternativeStyle, active: active }"
    >
      <div id="current">
        {{ value }}
        <img
          src="../assets/chevron.svg"
          :style="{ transform: active ? 'rotate(0)' : 'rotate(180deg)' }"
        />
      </div>
      <div
        id="list"
        :style="{
          height: active ? 'auto' : '0',
        }"
      >
        <div
          v-for="(item, index) in elements"
          :key="index"
          @click="select(item)"
          :style="{
            backgroundColor:
              getText(item) === value ? 'rgba(0, 0, 0, 0.3)' : 'transparent',
            '--hover-color': 'rgba(0, 0, 0, 0.2)',
          }"
        >
          {{ getText(item) }}
          <span id="locked" v-if="item.locked">PRO</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropDown",
  props: {
    value: String,
    elements: Array,
    fullWidth: Boolean,
    alternativeStyle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    getText(obj) {
      if (typeof obj === "string") return obj;
      else return obj.text;
    },
    close(e) {
      if (!this.$el.contains(e.target)) this.active = false;
    },
    select(obj) {
      if (obj.locked)
        this.$toasted.show(
          "Sorry, this option is only available for TradeUpHero Pro users!"
        );
      else this.$emit("input", this.getText(obj));
    },
  },
  computed: {
    wrapperStyle: function() {
      return {
        backgroundColor: this.backgroundColor,
        fontSize: this.fontSize + "px",
        color: this.color,
      };
    },
  },
  data() {
    return {
      active: false,
    };
  },
  mounted() {
    document.addEventListener("click", this.close);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  },
};
</script>

<style scoped lang="scss">
#wrapper {
  user-select: none;
  position: absolute;
  border-radius: 4px;
  background-color: #012a36;
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  box-sizing: border-box;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  &.active {
    box-shadow: white 0px 0px 5px;
    z-index: 20;
  }
}
#current {
  position: relative;
  padding: 7px 25px 7px 8px;
  z-index: 0;
  img {
    position: absolute;
    right: 8px;
    top: calc(20% + 7px);
    height: 18%;
  }
  &::after {
    content: "";
    position: absolute;
    right: 28px;
    top: 7px;
    width: 1px;
    height: 16px;
    background-color: white;
  }
}
#list {
  overflow: hidden;
  position: relative;
  z-index: 20;
  width: auto;
  height: auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #012a36;
}
#list div {
  width: 100%;
  white-space: nowrap;
  padding: 5px 25px 5px 8px;
}
#list div:hover {
  background-color: var(
    --hover-color
  ) !important; /*this works, dont let the red underline distract you*/
}
#outer-shell {
  position: relative;
  height: 25px;
}
.alt {
  background-color: transparent !important;
  padding: 0px !important;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  #current {
    background-color: transparent;
    border-bottom: 2px solid #c1eeff;
    &::after {
      display: none;
    }
  }
  #list {
    background-color: #c1eeff !important;
    color: #012a36;
    border-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
#locked {
  float: right;
  margin-right: 43px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: #8748fb;
}
</style>
