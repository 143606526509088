<template>
  <div id="float-meter-wrapper">
    <div id="fn"></div>
    <div id="mw"></div>
    <div id="ft"></div>
    <div id="ww"></div>
    <div id="bs"></div>
    <div id="max" :style="{ left: mF }" v-if="maxFloat !== null"></div>
  </div>
</template>

<script>
export default {
  name: "FloatMeter",
  props: {
    maxFloat: Number,
  },
  computed: {
    mF: function () {
      return "calc(" + Math.floor(this.maxFloat * 100) + "% - 5px)";
    },
  },
};
</script>

<style scoped>
#float-meter-wrapper {
  width: 100%;
  height: 10px;
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 5px;
  margin-bottom: 4px;
}
#fn {
  width: 7%;
  height: 100%;
  background-color: #85f05f;
}
#mw {
  width: 8%;
  height: 100%;
  background-color: #96d05b;
}
#ft {
  width: 23%;
  height: 100%;
  background-color: #fbdb5d;
}
#ww {
  width: 7%;
  height: 100%;
  background-color: #f08354;
}
#bs {
  width: 55%;
  height: 100%;
  background-color: #df5151;
}
#max {
  position: absolute;
  border: solid 7px transparent;
  border-bottom: solid 7px white;
  top: 0px;
}
</style>