<template>
  <div id="header">
    <router-link to="/"
      ><img id="logo" alt="TradeUpHero logo" src="../assets/logo.png"
    /></router-link>
    <upgrade-button
      v-if="!subscribed"
      id="upgrade"
      @upgrade="$emit('upgrade')"
    />
    <a id="steam" :href="login" v-if="!signedIn"
      ><img src="../assets/steam.png"
    /></a>
    <router-link id="name" to="/account" v-else
      >Whats Up, {{ name }} ?<img :src="avatar"
    /></router-link>
  </div>
</template>

<script>
import { mapState } from "vuex";
import UpgradeButton from "./UpgradeButton.vue";
export default {
  components: { UpgradeButton },
  name: "Header",
  computed: {
    ...mapState({
      signedIn: (state) => state.user.signedIn,
      subscribed: (state) => state.user.subscribed,
      name: (state) => state.user.name,
      avatar: (state) => state.user.avatar,
    }),
  },
  data() {
    return {
      login: `${process.env.VUE_APP_API_DOMAIN}/auth/authenticate`,
    };
  },
};
</script>

<style scoped lang="scss">
#header {
  width: 100%;
  height: 180px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
}
#logo {
  width: 200px;
  position: relative;
}
#steam {
  position: absolute;
  top: 60px;
  right: 200px;
  img {
    height: 50px;
  }
}
#name {
  position: absolute;
  top: 60px;
  right: 200px;
  display: flex;
  align-items: center;
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  text-decoration: none;
  img {
    height: 65px;
    width: 65px;
    border-radius: 34px;
    border: 3px solid #c1eeff;
    margin-left: 35px;
  }
}
#upgrade {
  position: absolute;
  top: 60px;
  left: 300px;
}
@media only screen and (max-width: 1365px) {
  #header {
    display: none;
  }
}
</style>
