<template>
  <div id="payment-wrapper">
    <div id="head">
      <span>
        Total: ${{ totalValue.toFixed(2) }}, this will subscribe you for
        {{ subDuration.toFixed(1) }} days!
      </span>
      <span id="url">
        Steam Trade-URL:
        <a href="https://steamcommunity.com/id/tgamingzerii/tradeoffers/privacy#trade_offer_access_url"
          target="_blank">Show my Trade-URL</a>
        <input v-model="editUrl" />
      </span>
    </div>
    <div id="inventory" v-if="
      this.$store.state.prices &&
      this.$store.state.currency &&
      this.$store.state.currencyExchangeRate
    ">
      <button v-if="subDuration >= 0" @click="submit"
        :style="{ backgroundColor: subDuration >= 30 ? '#d8b2c5' : '#ccc' }">
        Subscribe <img src="../assets/darkArrow.svg" />
      </button>
      <ItemCard v-for="item in inventory" :key="item.assetid" :name="item.name" :float="0" :percentage="item.percentage"
        isFullName inPayment :showFloat="false" :selected="selected[item.assetid] || false"
        @remove="select(item.assetid)" />
    </div>
    <h2 v-else id="loading">
      Loading<span class="loading-dot">.</span>
      <span class="loading-dot">.</span>
      <span class="loading-dot">.</span>
    </h2>
    <div id="popup-wrapper" :class="{ showing: showingPopup }">
      <div id="status-popup" :class="{ showing: showingPopup }">
        <p>
          We are sending a Trade Offer to your account. Make sure that the Trade
          ID is present in the Trade message.
        </p>
        <div id="trade-id">Security Code: {{ code }}</div>
        <div class="step">
          <div class="circle-border icon" v-if="!s1 && s1Waiting">
            <div class="circle-core"></div>
          </div>
          <img src="../assets/check.svg" class="icon" v-if="s1" />
          <img src="../assets/tradeFailed.svg" class="icon" v-if="!s1 && !s1Waiting" />
          <span>Step 1 Trade received</span>
        </div>
        <div class="step">
          <div class="circle-border icon" v-if="!s2 && s2Waiting">
            <div class="circle-core"></div>
          </div>
          <img src="../assets/check.svg" class="icon" v-if="s2" />
          <img src="../assets/tradeFailed.svg" class="icon" v-if="!s2 && !s2Waiting" />
          <span>Step 2 Trade sent</span>
        </div>
        <div class="step">
          <div class="circle-border icon" v-if="!s3 && s3Waiting">
            <div class="circle-core"></div>
          </div>
          <img src="../assets/check.svg" class="icon" v-if="s3" />
          <img src="../assets/tradeFailed.svg" class="icon" v-if="!s3 && !s3Waiting" />
          <span>Step 3 Trade accepted</span>
        </div>
        <div id="errors" v-if="error">
          <img src="../assets/error.svg" />
          <span>{{ error }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemCard from "@/components/ItemCard";
export default {
  name: "SkinPayment",
  components: { ItemCard },
  data() {
    return {
      inventory: null,
      prices: null,
      items: {},
      itemsEmpty: true,
      selected: {},
      error: null,
      totalValue: 0,
      lastStatus: "",
      code: "",
      editUrl: this.$store.state.user.tradeUrl,
      s1: false,
      s1Waiting: true,
      s2: false,
      s2Waiting: true,
      s3: false,
      s3Waiting: true,
      showingPopup: false,
    };
  },
  methods: {
    closePopup() {
      this.code = "";
      this.showingPopup = false;
      this.s1 = false;
      this.s1Waiting = true;
      this.s2 = false;
      this.s2Waiting = true;
      this.s3 = false;
      this.s3Waiting = true;
      this.lastStatus = "";
    },
    updateStatus() {
      fetch(`${process.env.VUE_APP_API_DOMAIN}/trade/status?code=${this.code}`)
        .then((response) => response.json())
        .then((result) => {
          if (result.success) {
            this.lastStatus = result.status;
            switch (result.status) {
              case "failed_trade_timedout":
                this.error = "Trade timed out";
                this.s2Waiting = false;
                this.s3Waiting = false;
                setTimeout(this.closePopup, 5000);
                break;
              case "failed_trade_too_many_offers":
                this.error = "Trade failed due to server issues";
                this.s2Waiting = false;
                this.s3Waiting = false;
                setTimeout(this.closePopup, 5000);
                break;
              case "failed_trade_timedout_confirmation":
                this.error = "Trade faild due to Steam issues";
                this.s2Waiting = false;
                this.s3Waiting = false;
                setTimeout(this.closePopup, 5000);
                break;
              case "failed_trade_counteroffer":
                this.error = "Trade failed because you sent a counteroffer";
                this.s2Waiting = false;
                this.s3Waiting = false;
                setTimeout(this.closePopup, 5000);
                break;
              case "failed_trade_declined_secondfactor":
                this.error = "Trade failed due to server issues";
                this.s2Waiting = false;
                this.s3Waiting = false;
                setTimeout(this.closePopup, 5000);
                break;
              case "failed_trade_invalid_items":
                this.error = "Trade failed because your items are invalid";
                this.s2Waiting = false;
                this.s3Waiting = false;
                setTimeout(this.closePopup, 5000);
                break;
              case "failed_trade_userdetails_unavailable":
                this.error =
                  "Trade failed because your steam account is private or steam is down";
                this.s2Waiting = false;
                this.s3Waiting = false;
                setTimeout(this.closePopup, 5000);
                break;
              case "failed_trade_escrow":
                this.error =
                  "Trade failed, your account is ineligible to Trade";
                this.s2Waiting = false;
                this.s3Waiting = false;
                setTimeout(this.closePopup, 5000);
                break;
              case "failed_trade_probation":
                this.error =
                  "Trade failed, your account is ineligible to Trade";
                this.s2Waiting = false;
                this.s3Waiting = false;
                setTimeout(this.closePopup, 5000);
                break;
              case "failed_trade_error":
                this.error = "Trade failed due to a Steam error";
                this.s2Waiting = false;
                this.s3Waiting = false;
                setTimeout(this.closePopup, 5000);
                break;
              case "trade_active":
              case "trade_sent_pending":
              case "trade_sent_confirmed":
              case "trade_sent":
                this.s2 = true;
                this.s2Waiting = false;
                setTimeout(this.updateStatus, 3000);
                break;
              case "trade_completed":
                this.s2 = true;
                this.s2Waiting = false;
                this.s3 = true;
                this.s3Waiting = false;
                this.showingPopup = false;

                this.$gtag.event("purchase", {
                  value: this.totalValue,
                  event_label: "skinpayment",
                });
                this.$emit("nextStep");
                break;
              case "sending_trade":
                setTimeout(this.updateStatus, 3000);
                break;
            }
          }
        });
    },
    async submit() {
      if (this.subDuration < 30) {
        this.$toasted.show("You need to subscribe for at least 30 days!");
        return;
      }
      if (
        !this.editUrl.startsWith(
          "https://steamcommunity.com/tradeoffer/new/?partner="
        )
      ) {
        this.$toasted.show("Please use a valid Trade-URL!");
        return;
      }
      await this.updateTradeUrl();
      const assetIds = [];
      for (const [assetId, isSelected] of Object.entries(this.selected)) {
        if (isSelected) assetIds.push(assetId);
      }

      this.showingPopup = true;
      const result = await fetch(`${process.env.VUE_APP_API_DOMAIN}/trade`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          assetIds,
          token: this.editUrl.split("&token=")[1],
        }),
      })
        .then((r) => r.json());
      if (result.success) {
        this.s1Waiting = false;
        this.s1 = true;
        this.code = result.code;
        setTimeout(this.updateStatus, 1);
      } else {
        this.error = result.reason;
        this.s1Waiting = false;
      }
    },
    async updateTradeUrl() {
      if (this.editUrl === this.tradeUrl) {
        return;
      }

      const url = new URL(
        `${process.env.VUE_APP_API_DOMAIN}/account/tradeurl`
      );
      url.searchParams.append("tradeUrl", this.editUrl);

      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        credentials: "include",
      })
        .then((r) => r.json());

      if (res.success) {
        this.$store.commit("updateTradeUrl", this.editUrl);
      }
    },
    select(assetid) {
      if (!this.selected[assetid]) {
        const totalItems = Object.values(this.selected).filter(Boolean).length;
        if (totalItems === 20) {
          this.$toasted.show(
            "You can only subscribe with a maximum of 20 Items"
          );
        } else this.selected[assetid] = true;
      } else {
        this.selected[assetid] = false;
      }
      this.$forceUpdate();
      this.calculateTotalValue();
    },
    calculateTotalValue() {
      if (!this.inventory) {
        this.totalValue = 0;
        return;
      }
      let value = 0;
      for (let i = 0; i < this.inventory.length; i++) {
        if (this.selected[this.inventory[i].assetid]) {
          value += this.inventory[i].price;
        }
      }
      this.totalValue = value;
    },
    load() {
      this.$store.dispatch("fetchWeaponsClient").then(() =>
        fetch(`${process.env.VUE_APP_API_DOMAIN}/inventory/${this.$store.state.user.uid}`)
          .then((data) => data.json())
          .then((res) => {
            if (!res.success) {
              this.error = res.msg;
              return;
            }
            const inv = [];
            for (let i = 0; i < res.length; i++) {
              if (this.prices[res[i].name])
                inv.push({
                  name: res[i].name,
                  assetid: res[i].assetid,
                  price:
                    this.$store.state.prices[res[i].name].customMedian / 100,
                });
            }
            this.inventory = inv;
          })
      );
    },
  },
  computed: {
    subDuration() {
      return (this.totalValue / 9.99) * 30;
    },
    status() {
      return this.code + this.lastStatus;
    },
  },
  created() {
    this.$store.dispatch("fetchPrices");
  },
  mounted() {
    fetch(`${process.env.VUE_APP_API_DOMAIN}/prices`)
      .then((response) => response.json())
      .then((data) => {
        this.prices = data;
        this.load();
      });
  },
};
</script>

<style lang="scss" scoped>
#payment-wrapper {
  width: 100%;
  height: 100%;
  font-family: Karla;

  #head {
    position: absolute;
    bottom: calc(100% + 25px);
    color: white;
    display: flex;
    justify-content: space-between;
    width: 100%;

    #url {
      position: relative;

      input {
        background-color: transparent;
        border: none;
        border-bottom: solid 1px #c1eeff;
        color: #fff;
        font-family: Karla;
      }

      a {
        position: absolute;
        top: calc(100% + 4px);
        margin-left: 0px;
        color: #d8b2c5;
        font-family: Karla;
        font-size: 12px;
        text-decoration: none;
      }
    }
  }

  #popup-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
    z-index: 20;

    &.showing {
      opacity: 1;
      pointer-events: all;
    }
  }

  #status-popup {
    position: fixed;
    right: 30vw;
    width: 40vw;
    background: #d8b2c5;
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
      0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    padding: 20px 30px;
    box-sizing: border-box;
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: calc(20vh - 50px);
    transition: top 0.2s ease-in-out;

    &.showing {
      top: 20vh;
    }

    p {
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
    }

    #trade-id {
      background: #f8ddea;
      border-radius: 2px;
      padding: 3px 14px;
      margin-bottom: 20px;
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #012a36;
      min-width: 50%;
      box-sizing: border-box;
    }

    .step {
      width: 40%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .icon {
        width: 20px;
        height: 20px;
        margin-right: 20px;
      }

      @keyframes spin {
        from {
          transform: rotate(0);
        }

        to {
          transform: rotate(359deg);
        }
      }

      .circle-border {
        width: 20px;
        height: 20px;
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: rgb(193, 238, 255);
        background: linear-gradient(0deg,
            rgba(193, 238, 255, 0.1) 33%,
            rgba(193, 238, 255, 1) 100%);
        animation: spin 0.8s linear 0s infinite;
      }

      .circle-core {
        width: 100%;
        height: 100%;
        background-color: #d8b2c5;
        border-radius: 50%;
      }
    }

    #errors {
      width: 50%;
      background-color: rgba(239, 119, 119, 0.5);
      border: 1px solid #eb4b4b;
      box-sizing: border-box;
      border-radius: 2px;
      padding: 7px 33px;
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #012a36;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;

      img {
        margin-right: 20px;
      }
    }
  }
}

#inventory {
  margin: 4px 4px 4px 4px;
  box-sizing: border-box;
  padding: 10px 0 10px 0;
  height: calc(100% - 8px);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 120px));
  grid-auto-rows: 120px;
  grid-gap: 10px;
  overflow-y: auto;
  margin-left: auto;
  justify-content: center;

  button {
    position: absolute;
    right: 0;
    top: calc(100% + 100px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
      0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    border: none;
    padding: 7px 25px;
    display: flex;
    align-items: center;
    z-index: 50;

    &:hover {
      cursor: pointer;
    }

    img {
      transform: rotate(180deg);
      margin-left: 14px;
    }
  }

  // custom scrollbar
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #012a36;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #012a36;
  }
}

#loading {
  color: #c1eeff;
  font-family: Karla;
  font-weight: bold;
  font-size: 36px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.loading-dot {
  animation: loading-dots 1.4s infinite;
  animation-fill-mode: both;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loading-dots {
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

@media only screen and (max-width: 1365px) {
  #head {
    flex-direction: column !important;

    #url {
      margin-top: 10px;
    }
  }

  #status-popup {
    width: 90vw !important;
    right: 5vw !important;

    .step {
      width: 80% !important;
    }
  }

  #inventory button {
    top: calc(100% + 40px) !important;
  }
}
</style>
