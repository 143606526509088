<template>
  <div id="app">
    <img id="background-image" src="./assets/background.png" />
    <Header
      v-if="route !== 'Subscribe'"
      @upgrade="showingUpgradePopup = true"
    ></Header>
    <Menu v-if="route !== 'Subscribe'"></Menu>
    <router-view
      @changeCurrency="changeCurrency"
      @changeData="changeData"
      @upgrade="showingUpgradePopup = true"
      class="content-wrapper"
      v-if="checkedUser"
    ></router-view>
    <CookiePopup></CookiePopup>
    <Signup
      v-if="checkedUser && signedIn && !registered"
      @accepted="$store.commit('setRegistered', true)"
    />
    <UpgradePopup
      :showing="showingUpgradePopup"
      @clicked="showingUpgradePopup = false"
      @close="showingUpgradePopup = false"
    />
    <NewsPopup />
  </div>
</template>
<script>
import { mapState } from "vuex";
import Header from "@/components/Header";
import Menu from "@/components/Menu";
import CookiePopup from "@/components/CookiePopup";
import Signup from "@/components/Signup";
import UpgradePopup from "@/components/UpgradePopup";
import NewsPopup from "@/components/NewsPopup";

export default {
  name: "app",
  components: {
    CookiePopup,
    Header,
    Menu,
    Signup,
    UpgradePopup,
    NewsPopup,
  },
  data() {
    return {
      activeSite: "tradeups",
      version: process.env.VUE_APP_VERSION,
      checkedSignIn: false,
      showingUpgradePopup: false,
    };
  },
  computed: {
    route() {
      return this.$route.name;
    },
    ...mapState({
      checkedUser: (state) => state.user.checked,
      signedIn: (state) => state.user.signedIn,
      registered: (state) => state.user.registered,
      subscribed: (state) => state.user.subscribed,
    }),
  },
  mounted: function () {
    this.$store.commit("loadCurrency");
    this.$store.dispatch("fetchUser").then(() => {
      let status = "";
      if (!this.$store.state.user.status) status = "guest";
      else if (!this.$store.state.user.subscribed) status = "registered";
      else status = "pro";
      if (this.$gtag !== undefined) {
        this.$gtag.customMap({ dimension1: "status" });
        this.$gtag.config({
          status,
        });
      }
    });
    this.$store.dispatch("fetchCurrencyExchangeRate");
  },
  methods: {
    changeCurrency(e) {
      this.$store.dispatch("changeCurrency", e);
    },
    changeData(e) {
      this.$store.commit("updateUserDetails", e);
    },
  },
};
</script>

<style lang="scss">
.content-wrapper {
  position: relative;
  padding-top: 160px;
  width: calc(100% - 300px);
  box-sizing: border-box;
  padding-left: 250px;
  padding-right: 50px;
  z-index: 5;
}
html {
  /*scroll-behavior: smooth;*/
}
#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  background-color: #012a36;
  #background-image {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
body {
  margin: 0;
}
.toast {
  background: #d8b2c5 !important;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px !important;
  font-family: Karla;
  font-style: normal;
  font-size: 12px !important;
  line-height: 14px !important;
  font-weight: bold !important;
  color: #012a36 !important;
  a {
    color: #012a36 !important;
  }
}
@media only screen and (max-width: 1365px) {
  .content-wrapper {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 5;
  }
}
</style>
