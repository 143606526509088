<template>
  <div id="favorites-wrapper">
    <div id="tradeups">
      <div id="header" v-if="!serverCalled">Loading your Favorites...</div>
      <div
        v-else-if="serverCalled && favoriteTradeUps.length === 0"
        id="header"
      >
        You do not have any favorite TradeUps
      </div>
      <div id="header" v-else>Your Favorites</div>
      <template v-if="!serverCalled">
        <TradeUp v-for="n in 10" :key="n" placeholder />
      </template>
      <TradeUp
        v-else
        v-for="item in favoriteTradeUps"
        :key="item.uti"
        v-bind:tradeup="item"
        :favorite="favorites.includes(item.uti)"
        v-on:toastComplete="recalcFavorites"
      />
    </div>
  </div>
</template>

<script>
import { getMultipleUtis } from "../tradeUps";
import { mapState } from "vuex";
import TradeUp from "@/components/TradeUp";
export default {
  name: "Favorites",
  components: { TradeUp },
  computed: {
    ...mapState({
      favorites: (state) => state.user.favorites,
    }),
  },
  data() {
    return {
      tradeUps: [],
      favoriteTradeUps: [],
      serverCalled: false,
    };
  },
  methods: {
    recalcFavorites() {
      this.favoriteTradeUps = this.tradeUps.filter((t) =>
        this.favorites.includes(t.uti)
      );
    },
  },
  created() {
    Promise.all([
      this.$store.dispatch("fetchWeaponsClient"),
      this.$store.dispatch("fetchFavorites"),
    ])

      .then(() => {
        if (this.$store.state.user.favorites.length === 0) {
          this.serverCalled = true;
          return;
        }
        return getMultipleUtis(
          this.$store.state.user.favorites,
          this.$store.state.weaponsClient
        ).then((tradeUps) => {
          this.tradeUps = tradeUps;
          this.recalcFavorites();
          this.serverCalled = true;
        });
      })
      .catch((err) => {
        console.log(err);
        this.serverCalled = true;
      });
  },
};
</script>

<style scoped lang="scss">
#favorites-wrapper {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding-left: 220px;
  display: flex;
  flex-direction: row;
}
#tradeups {
  width: 80%;
}
#no-trade-ups {
  width: 100%;
  font-size: 42px;
  text-align: center;
  font-weight: bold;
  color: #ccc;
}
#header {
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;

  color: #ffffff;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1365px) {
  #favorites-wrapper {
    padding-left: 10px;
    #tradeups {
      width: 100%;
    }
  }
}
</style>
