<template>
  <div>
    <div
      id="burger"
      @click="menuVisible = !menuVisible"
      :class="{ active: menuVisible }"
    >
      <div id="inner"></div>
    </div>
    <div id="header">{{ headerText }}</div>
    <div id="menu" :class="{ closed: !menuVisible }">
      <div id="menu-items">
        <router-link to="/" @click.native="menuVisible = false">
          <div class="menu-item">
            <img src="../assets/TradeupsIcon.svg" />
            <div>TradeUps</div>
          </div>
        </router-link>
        <router-link to="/calculator" @click.native="menuVisible = false">
          <div class="menu-item">
            <img src="../assets/CalculatorIcon.svg" />
            <div>Calculator</div>
          </div>
        </router-link>
        <a @click="favorites" v-if="!subscribed">
          <div class="menu-item">
            <img src="../assets/FavoritesIcon.svg" />
            <span id="locked">PRO</span>
            <div>Favorites</div>
          </div>
        </a>
        <router-link to="/favorites" v-else @click.native="menuVisible = false">
          <div class="menu-item">
            <img src="../assets/FavoritesIcon.svg" />
            <div>Favorites</div>
          </div>
        </router-link>
        <router-link to="/inventory" @click.native="menuVisible = false">
          <div class="menu-item">
            <img src="../assets/InventoryIcon.svg" />
            <div>Inventory</div>
          </div>
        </router-link>
        <router-link
          to="/account"
          v-if="signedIn"
          @click.native="menuVisible = false"
        >
          <div class="menu-item">
            <img src="../assets/SettingsIcon.svg" />
            <div>Settings</div>
          </div>
        </router-link>
        <router-link to="/support" @click.native="menuVisible = false">
          <div class="menu-item">
            <img src="../assets/SupportIcon.svg" />
            <div>Support</div>
          </div>
        </router-link>
      </div>

      <a :href="login" id="login" v-if="!signedIn">Login</a>

      <div id="copyright">
        <router-link to="legalnotice" @click.native="menuVisible = false"
          >Legal Notice</router-link
        >
        <router-link to="tos" @click.native="menuVisible = false"
          >Terms of Service</router-link
        >
        <router-link to="privacy" @click.native="menuVisible = false"
          >Privacy Policy</router-link
        >
        <div>TradeUpHero V {{ version }}</div>
        <div>Powered by Steam.</div>
        <div>&copy; {{ new Date().getFullYear() }} TradeUpHero</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Menu",
  computed: {
    ...mapState({
      signedIn: (state) => state.user.signedIn,
      subscribed: (state) => state.user.subscribed,
    }),
    headerText() {
      return this.$route.name;
    },
  },
  data() {
    return {
      menuVisible: false,
      version: process.env.VUE_APP_VERSION,
      login: `${process.env.VUE_APP_API_DOMAIN}/auth/authenticate`,
    };
  },
  methods: {
    favorites() {
      this.menuVisible = false;
      this.$toasted.show("Sorry, favorites is a Pro feature");
    },
  },
  mounted() {
    const touchableElement = document.body;
    let touchstartX;

    touchableElement.addEventListener(
      "touchstart",
      (event) => {
        touchstartX = event.changedTouches[0].screenX;
      },
      false
    );

    touchableElement.addEventListener(
      "touchend",
      (event) => {
        const difference = event.changedTouches[0].screenX - touchstartX;
        if (difference < -50) this.menuVisible = false;
        if (touchstartX < 150 && difference > 50) this.menuVisible = true;
      },
      false
    );
  },
};
</script>

<style scoped lang="scss">
#menu {
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100vh;
  height: auto;
  width: 200px;
  padding-top: 230px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 51;
}
#burger {
  position: fixed;
  top: 60px;
  left: 30px;
  z-index: 52;
  width: 40px;
  height: 22px;
  #inner {
    position: relative;
    width: 40px;
    height: 2px;
    background-color: #eb4b4b;
    &::before {
      content: "";
      position: absolute;
      top: calc(100% + 8px);
      left: 0;
      height: 2px;
      width: 40px;
      background-color: #eb4b4b;
    }
    &::after {
      content: "";
      position: absolute;
      top: calc(100% + 18px);
      left: 0;
      height: 2px;
      width: 25px;
      background-color: #eb4b4b;
      transition: width 0.2s ease-in-out;
    }
  }
  &:hover {
    cursor: pointer;
  }
  &.active {
    #inner::after {
      width: 40px;
    }
  }
}
#copyright {
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #c1eeff;
  padding: 5px 25px 5px 38px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
  div {
    margin-bottom: 2px;
  }
  a {
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #c1eeff;
    margin-bottom: 20px;
    &:hover::after {
      width: 50%;
    }
  }
  .router-link-exact-active {
    &::after {
      width: 100% !important;
    }
  }
}
a {
  text-decoration: none;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 1px;
    top: 100%;
    left: 0;
    background-color: #c1eeff;
    transition: width 0.2s ease-in-out;
  }
}

.menu-item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 55px;
  overflow: hidden;
  transition: width 0.2s ease-in-out;
  border-radius: 4px;
  position: relative;
  img {
    width: 24px !important;
    margin-right: 38px;
    margin-left: 38px;
    &:hover {
      cursor: pointer;
    }
  }
  div {
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: #c1eeff;
    &:hover {
      cursor: pointer;
    }
  }
  #locked {
    position: absolute;
    left: 53px;
    top: 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #8748fb;
  }
}
.router-link-exact-active .menu-item {
  background: linear-gradient(
    262.39deg,
    rgba(56, 197, 250, 0.25) 0%,
    rgba(0, 185, 255, 0) 79.85%
  );
}
#menu.closed .menu-item {
  width: 77px;
}

#login {
  display: none;
}

#header {
  display: none;
}

@media only screen and (max-width: 1365px) {
  #header {
    display: block;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 4;
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #c1eeff;
  }
  #menu {
    transition: width 0.2s ease-in-out;
    width: 250px;
    background: linear-gradient(
      90deg,
      #012a36 74.37%,
      rgba(1, 42, 54, 0) 98.46%
    );
    padding-top: 120px;
    overflow: hidden;
    .menu-item {
      width: 200px;
    }
    &.closed {
      width: 0;
    }
    #login {
      display: block;
      background: #c1eeff;
      border-radius: 4px;
      font-family: Karla;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #012a36;
      padding: 7px 24px;
      margin-left: 38px;
      width: fit-content;
    }
  }
}
</style>
