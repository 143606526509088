<template>
  <div id="outer-wrapper" @click="$emit('close')" :class="{ visible: showing }">
    <div id="wrapper" @click.stop :class="{ visible: showing }">
      <img id="go-pro" src="../assets/goPro.svg" />
      <span id="go-home">or go home</span>
      <p>
        Upgrade to TradeUpHero Pro to gain access to all these amazing features
        that help you profit more from TradeUps!
      </p>
      <ul>
        <li>Ad-free experience</li>
        <li>TradeUps are no longer limited to 25% profit</li>
        <li>Show profitability after steamtax</li>
        <li>Save the best TradeUps in your favorites tab</li>
        <li>Take advantage of our more accurate custom pricing options</li>
        <li>
          More filter options like difficulty and volume let you find even
          better TradeUps
        </li>
      </ul>
      <div id="pricetag">
        <span id="from">from</span><img src="../assets/pricetag.svg" />
      </div>
      <button @click="upgrade">Upgrade</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "UpgradePopup",
  props: {
    showing: Boolean,
  },
  methods: {
    upgrade() {
      this.$router.push("/subscribe");
      this.$emit("clicked");
    },
  },
};
</script>

<style scoped lang="scss">
#outer-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}
#wrapper {
  position: fixed;
  top: calc(20vh - 20px);
  left: 25vw;
  width: 50vw;
  height: 600px;
  background-color: #d8b2c5;
  z-index: 100;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 35px 185px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: top 0.2s ease-in-out;
  pointer-events: none;
  &.visible {
    pointer-events: all;
    top: 20vh;
  }
  #go-pro {
  }
  #go-home {
    font-family: Karla;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #8748fb;
    margin-top: -10px;
    margin-bottom: 20px;
  }
  p {
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #012a36;
  }
  ul {
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: #012a36;
    list-style-type: none;
    li {
      position: relative;
      margin-bottom: 10px;
      &::before {
        content: url("../assets/check.svg");
        position: absolute;
        right: calc(100% + 15px);
        top: 2px;
        width: 16px;
        height: 16px;
      }
    }
  }
  #pricetag {
    position: relative;
    #from {
      position: absolute;
      right: calc(100% + 10px);
      bottom: 21px;
      font-family: Karla;
      font-size: 25px;
      line-height: 20px;
      color: #eb4b4b;
      font-weight: bold;
    }
  }
  button {
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: #012a36;
    padding: 10px 42px;
    background: #eb4b4b;
    border-radius: 4px;
    position: absolute;
    bottom: 30px;
    right: 40px;
    border: none;
    transition: opacity 0.2s ease, box-shadow 0.2s ease;
    &:hover {
      cursor: pointer;
      opacity: 0.9;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    }
  }
}
@media only screen and (max-width: 1365px) {
  #wrapper {
    left: 5%;
    width: 90%;
    padding: 20px 20px;
    height: 100%;
    top: auto !important;
    bottom: 20px;
    overflow-y: auto;
    max-height: calc(100vh - 30px);
    #go-pro {
      max-width: 90%;
    }
    #pricetag {
      display: flex;
      justify-content: center;
      img {
        width: 70%;
      }
      #from {
        display: none;
      }
    }
    button {
      position: relative;
      left: 40px;
      right: auto;
      top: 5px;
    }
  }
}
</style>