<template>
  <div id="wrapper">
    <ins
      class="adsbygoogle"
      :style="{ display: 'flex' }"
      :data-ad-client="googleAdId"
      data-ad-slot="8115863785"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  </div>
</template>

<script>
export default {
  name: "Ad",
  props: {},
  data() {
    return {
      googleInit: null,
      googleAdId: "ca-pub-5222349959660935",
    };
  },
  mounted() {
    let timeout = 200;
    this.googleInit = setTimeout(() => {
      if (typeof window !== "undefined")
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, timeout);
  },
  destroyed() {
    if (this.googleInit) clearTimeout(this.googleInit);
  },
};
</script>

<style lang="scss" scoped>
#wrapper {
  color: whitesmoke;
  background-color: rgba(238, 250, 255, 0.2);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  margin-bottom: 25px;
  font-family: Karla;
  font-style: normal;
  padding: 20px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  .adsbygoogle {
    width: 100%;
    justify-content: center;
  }
}
</style>