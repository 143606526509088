const steamListingUri = "https://steamcommunity.com/market/listings/730/";

export function getMultipleUtis(utis, weapons) {
	console.log(utis);
	const utids = utis.join(";;");
	return fetch(`${process.env.VUE_APP_API_DOMAIN}/calculate/multiple/${utids}`)
		.then((r) => r.json())
		.then((data) => {
			if (!data.success) return [];
			return data.tradeUps.map((i) => parseSingleItem(i, weapons));
		});
}

export function getTradeUps(e, offset, amount, weapons) {
	const url = new URL(`${process.env.VUE_APP_API_DOMAIN}/tradeups`);
	url.searchParams.append("rarity", e.rarity);
	url.searchParams.append("difficulty", 100 - e.difficulty);
	url.searchParams.append("statTrak", e.statTrak);
	url.searchParams.append("filterMaxCosts", e.price * 100);
	url.searchParams.append("filterBattleScarred", e.filterBS);
	url.searchParams.append("filterLowPrice", e.filterUnter);
	url.searchParams.append("filterMinVolume", e.minVolume);
	url.searchParams.append("token", encodeURIComponent(e.sid));
	url.searchParams.append("inputPriceType", e.input);
	url.searchParams.append("outputPriceType", e.output);
	url.searchParams.append("offset", offset);
	url.searchParams.append("amount", amount);

	return fetch(url, {
		method: "GET",
		headers: {
			pragma: "no-cache",
			"cache-control": "no-cache",
		},
	})
		.then((r) => r.json())
		.then((resp) => ({
			tradeUps: resp.page.tradeUps.map((t) => parseSingleItem(t, weapons)),
			total: resp.page.total,
			mostProfitable: resp.mostProfitable,
		}));
}

export function itemArrayToUtid(items, weaponsClient) {
	const reducedList = [];
	for (const item of items) {
		const itemIndex = reducedList.findIndex(
			(reducedItem) =>
				reducedItem.id === getItemId(item, weaponsClient) &&
				reducedItem.float === Math.round(item.float * 100_000),
		);
		if (itemIndex === -1) {
			reducedList.push({
				id: getItemId(item, weaponsClient),
				amount: 1,
				float: Math.round(item.float * 100_000),
			});
		} else {
			reducedList[itemIndex].amount++;
		}
	}
	const utid = getUti(reducedList, items[0].statTrak);
	return utid;
}

export function getInputSkins(tradeup, weaponsClient) {
	const inputSkins = [];
	for (let i = 0; i < tradeup.items.length; i++) {
		for (let j = 0; j < tradeup.items[i].amount; j++) {
			inputSkins.push({
				name: weaponsClient[tradeup.items[i].id - 1].name,
				float: tradeup.items[i].float / 100000,
				rarity: weaponsClient[tradeup.items[i].id - 1].rarity,
				statTrak: tradeup.uti[0] === "1",
			});
		}
	}
	return inputSkins;
}

function getItemId(item, weaponsClient) {
	const index = weaponsClient.findIndex((weapon) => weapon.name === item.name);
	return index === -1 ? -1 : index + 1;
}

export function parseSingleItem(current, weaponsClient) {
	const statTrak = current.uti[0] === "1";
	const prefix = statTrak ? "StatTrak™ " : "";
	let outcomes = [];

	//add outcomes
	const items = [];
	for (let j = 0; j < current.items.length; j++) {
		const item = current.items[j];
		const itemOutcomes = item.outcomes;
		const amount = item.amount;
		for (let i = 0; i < itemOutcomes.length; i++) {
			const outcome = weaponsClient[itemOutcomes[i].id - 1];
			const chance = (amount / current.outcomesAmount) * 100;
			const name = prefix + outcome.name + floatToString(itemOutcomes[i].float);
			const profit = itemOutcomes[i].price - current.totalCost;
			outcomes.push({
				name,
				chance: chance.toFixed(0),
				price: itemOutcomes[i].price.toFixed(2),
				link: `${steamListingUri}${name}`,
				profit: profit,
				percentageProfit: profit / current.totalCost,
				volume: itemOutcomes[i].volume,
				float: itemOutcomes[i].float / 100_000,
			});
		}
		items.push(item);
	}

	//merge outcomes
	const mergedOutcomes = [];
	for (let i = 0; i < outcomes.length; i++) {
		let foundOutcome = false;
		for (let j = 0; j < mergedOutcomes.length; j++) {
			if (outcomes[i].name === mergedOutcomes[j].name) {
				//outcome exists twice
				mergedOutcomes[j].chance = (
					Number.parseFloat(mergedOutcomes[j].chance) +
					Number.parseFloat(outcomes[i].chance)
				).toFixed(2);
				foundOutcome = true;
			}
		}
		if (!foundOutcome) {
			mergedOutcomes.push(outcomes[i]);
		}
	}
	outcomes = mergedOutcomes;

	//handle alternatives and create items
	const finalItems = [];
	for (let j = 0; j < items.length; j++) {
		const alts = items[j].alternatives;
		const alternatives = [];
		if (alts)
			for (let i = 0; i < alts.length; i++) {
				const alternative = weaponsClient[alts[i].id - 1];
				const name = prefix + alternative.name + floatToString(alts[i].float);
				alternatives.push({
					name,
					price: alts[i].price,
					link: `${steamListingUri}${name}`,
				});
			}
		const itemDetails = weaponsClient[items[j].id - 1];
		const name = prefix + itemDetails.name + floatToString(items[j].float);
		finalItems.push({
			name,
			amount: items[j].amount === 10 ? 5 : items[j].amount,
			price: items[j].price.toFixed(2),
			link: `${steamListingUri}${name}`,
			alternatives: alternatives,
		});
	}

	//handle tradeup with just 1 item
	if (finalItems.length === 1) {
		finalItems.push(JSON.parse(JSON.stringify(finalItems[0])));
	}

	const tradeUp = {
		profit: {
			percent: `${current.percentageProfit.toFixed(0)}%`,
			total: current.totalProfit.toFixed(2),
		},
		cost: current.totalCost.toFixed(2),
		averageValue: current.averagePrice.toFixed(2),
		maxFloat: (current.maxFloat / 100_000).toFixed(2),
		uti: current.uti,
		items: finalItems,
		results: outcomes,
		raw: current,
	};
	return tradeUp;
}

function floatToString(float) {
	return conditionToString(floatToCondition(float));
}

function floatToCondition(float) {
	const floatValue = float / 100_000;
	if (floatValue <= 0.07) return 0;
	if (floatValue <= 0.15) return 1;
	if (floatValue <= 0.38) return 2;
	if (floatValue <= 0.45) return 3;
	return 4;
}

function conditionToString(condition) {
	switch (condition) {
		case 0:
			return "(Factory New)";
		case 1:
			return "(Minimal Wear)";
		case 2:
			return "(Field-Tested)";
		case 3:
			return "(Well-Worn)";
		case 4:
			return "(Battle-Scarred)";
	}
}

// has to be in sync with the backend (uti.js)
function getUti(items, statTrak) {
	const parts = items.map((item) => {
		const amount = item.amount === 10 ? "0" : item.amount.toString();
		const itemId = item.id.toString().padStart(4, "0");
		const float = item.float.toString();
		return `${itemId}${amount}${float}`;
	});

	parts.sort();

	parts.unshift(statTrak ? "1" : "0");

	return parts.join(";");
}
