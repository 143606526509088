<template>
  <div id="input-wrapper">
    <input
      type="number"
      :value="value"
      @input="change"
      ref="input"
      id="input"
      :readonly="locked"
    />
    <img id="up" src="../assets/chevron.svg" @click="manualChange(1)" />
    <img id="down" src="../assets/chevron.svg" @click="manualChange(-1)" />
    <span id="locked" v-if="locked"> PRO</span>
  </div>
</template>

<script>
export default {
  name: "CustomNumberInput",
  props: {
    value: Number,
    locked: Boolean,
  },
  methods: {
    change() {
      if (this.locked) {
        this.$toasted.show("Sorry, this is a pro Feature");
        return;
      }
      this.$emit("input", Number.parseFloat(this.$refs["input"].value));
    },
    manualChange(num) {
      if (this.locked) {
        this.$toasted.show("Sorry, this is a pro Feature");
        return;
      }
      this.$emit("input", this.value + num);
    },
  },
};
</script>

<style lang="scss" scoped>
#input-wrapper {
  background-color: #012a36;
  width: 100%;
  height: 30px;
  border-radius: 4px;
  position: relative;
  #input {
    box-sizing: border-box;
    background-color: transparent;
    width: 100%;
    height: 100%;
    border: none;
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #ffffff;
    padding-left: 10px;
    -moz-appearance: textfield;
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
  #up {
    position: absolute;
    right: 5px;
    top: 3px;
    width: 12px;
    padding: 3px;
    &:hover {
      cursor: pointer;
    }
  }
  #down {
    position: absolute;
    right: 5px;
    bottom: 1px;
    padding: 3px;
    width: 12px;
    transform: rotate(180deg);
    &:hover {
      cursor: pointer;
    }
  }
  #locked {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    color: #8748fb;
    position: absolute;
    right: 35px;
    bottom: 9px;
  }
  &::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 16px;
    right: 28px;
    top: 7px;
    background-color: #fff;
  }
}
</style>