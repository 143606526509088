<template>
  <div id="wrapper">
    <div id="background">
      <div id="profit">
        <span> {{ percentage }}% </span>
        <span>+$1.11</span>
      </div>

      <div id="add-info">
        <span class="info-line"><span>Cost:</span><span>50</span> </span>
        <span class="info-line"><span>Ø Value:</span> <span>$50</span></span>
        <div id="float">
          <FloatMeter :max-float="0.4"></FloatMeter>
          <span class="info-line"
            ><span>MAX FLOAT:</span> <span>0.4</span></span
          >
        </div>
      </div>
    </div>
    <div id="overlay">
      <span id="info" v-html="teaserText"></span>
      <UpgradeButton @upgrade="$emit('upgrade')" />
    </div>
  </div>
</template>

<script>
import FloatMeter from "./FloatMeter.vue";
import UpgradeButton from "./UpgradeButton.vue";
export default {
  name: "Teaser",
  components: { FloatMeter, UpgradeButton },
  props: { percentage: Number },
  computed: {
    teaserText() {
      if (this.percentage <= 25)
        return "If you had TradeUpHero Pro, you would have access to more advanced Settings to find even better, more profitable TradeUps!";
      return `If you had TradeUpHero Pro, you could see this <strong style="color: #D4B2D8">${this.percentage}% profit</strong> tradeup here!`;
    },
  },
};
</script>

<style lang="scss" scoped>
#wrapper {
  color: whitesmoke;
  background-color: rgba(238, 250, 255, 0.2);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  margin-bottom: 25px;
  font-family: Karla;
  font-style: normal;
  padding: 20px;
  height: 150px;
  position: relative;
  #overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 30px;
    text-align: center;
    #info {
      font-family: Karla;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      color: #c1eeff;
    }
  }

  #background {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: center;
    filter: blur(10px);
    #profit {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 40%;
      font-weight: bold;
      font-size: 24px;
      color: #4beb5b;
      width: 15%;
      box-sizing: border-box;
      padding-right: 10%;
      margin-left: 6%;
    }
    #add-info {
      height: 100%;
      width: 15%;
      margin-left: 5%;
      margin-right: calc(5% + 35px);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .info-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>