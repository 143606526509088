import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const store = new Vuex.Store({
	state: () => ({
		user: {
			checked: false,
			uid: null,
			signedIn: false,
			avatar: null,
			name: null,
			registered: false,
			email: null,
			tradeUrl: "",
			gumroadKey: null,
			subscribed: false,
			excludeSteamTax: false,
			favorites: [],
		},
		currency: "USD",
		currencyExchangeRate: 1,
		weaponsClient: null,
		prices: null,
	}),
	getters: {},
	mutations: {
		updateUser(state, user) {
			state.user.checked = true;
			state.user.uid = user.uid;
			state.user.signedIn = user.status;
			state.user.avatar = user.image;
			state.user.name = user.name;
			state.user.registered = user.registered;
			state.user.email = user.email;
			state.user.tradeUrl = user.tradeUrl;
			state.user.gumroadKey = user.gumroadKey;
			state.user.subscribed = user.subscribed;
			state.user.excludeSteamTax = user.excludeSteamTax;
		},
		updateUserDetails(state, { email, gumroadKey, tradeUrl, excludeSteamTax }) {
			state.user.email = email;
			state.user.gumroadKey = gumroadKey;
			state.user.tradeUrl = tradeUrl;
			state.user.excludeSteamTax = excludeSteamTax;
		},
		updateTradeUrl(state, tradeUrl) {
			state.user.tradeUrl = tradeUrl;
		},
		setRegistered(state, registered) {
			state.user.registered = registered;
		},
		setFavorites(state, favorites) {
			state.user.favorites = favorites;
		},
		addFavorite(state, favorite) {
			if (!state.user.favorites.includes(favorite)) {
				state.user.favorites.push(favorite);
			}
		},
		removeFavorite(state, favorite) {
			state.user.favorites = state.user.favorites.filter(
				(uti) => uti !== favorite,
			);
		},
		loadCurrency(state) {
			state.currency = localStorage.getItem("currency") ?? "USD";
		},
		updateCurrencyExchangeRate(state, exchangeRate) {
			state.currencyExchangeRate = exchangeRate;
		},
		setWeaponsClient(state, weaponsClient) {
			state.weaponsClient = weaponsClient;
		},
		setPrices(state, prices) {
			state.prices = prices;
		},
	},
	actions: {
		async fetchUser(context) {
			const userData = await fetch(`${process.env.VUE_APP_API_DOMAIN}/account/info`, {
				credentials: "include",
			}).then((r) => r.json());
			context.commit("updateUser", userData);
		},
		async fetchFavorites(context) {
			if (context.state.user.uid === null) return;
			const result = await fetch(
				`${process.env.VUE_APP_API_DOMAIN}/account/favorites`,
				{
					credentials: "include",
				},
			).then((r) => r.json());
			if (!result.success) return;
			context.commit("setFavorites", result.utis);
		},
		async fetchCurrencyExchangeRate(context) {
			if (!context.state.currency) context.commit("loadCurrency");

			if (context.state.currency === "USD") {
				context.commit("updateCurrencyExchangeRate", 1);
				return;
			}

			const latestExchangeRates = await fetch(
				`${process.env.VUE_APP_FOREX_API}/latest?base=USD`,
			).then((r) => r.json());
			if (!latestExchangeRates.success) {
				return;
			}
			const currency = context.state.currency.toUpperCase();
			if (!Object.hasOwn(latestExchangeRates.rates, currency)) {
				return;
			}
			context.commit(
				"updateCurrencyExchangeRate",
				latestExchangeRates.rates[currency],
			);
		},
		async fetchWeaponsClient(context) {
			if (context.state.weaponsClient !== null) return;
			const data = await fetch(
				`${process.env.VUE_APP_API_DOMAIN}/weapons_client.min.json`,
			).then((r) => r.json());
			context.commit("setWeaponsClient", data);
		},
		async fetchPrices(context) {
			const prices = await fetch(`${process.env.VUE_APP_API_DOMAIN}/prices`).then(
				(response) => response.json(),
			);
			context.commit("setPrices", prices);
		},
		changeCurrency(context, currency) {
			context.state.currency = currency;
			localStorage.setItem("currency", currency);
			return context.dispatch("fetchCurrencyExchangeRate");
		},
		async saveUserDetails(context, body) {
			const data = await fetch(`${process.env.VUE_APP_API_DOMAIN}/account/update`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(body),
				credentials: "include",
			})
				.then((r) => r.json())
				.catch(() => ({ success: false }));
			if (!data.success) {
				return false;
			}
			context.commit("updateUserDetails", body);
			return true;
		},
	},
});
